// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;

.flex-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table {
  height: calc(100% - 40px);
  position: relative;
  .table-header {
    width: 100%;
    background: rgba(24,144,255,0.10);
    border-spacing: 0px;
    box-sizing: border-box;
    height: 40px;
    th {
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      padding-left: 5px;
      color: #777777;
      border-bottom: 1px solid #E8E8E8;
    }
  }
  .list {
    height: calc(100% - 40px);
    width: 100%;
    overflow: scroll;
    box-sizing: border-box;
    .item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #E8E8E8;
      padding-left: 5px;
      position: relative;
      .life {
        color: #F45F00;
      }
      &:hover {
        background-color: rgba(24,144,255,0.02);
      }
      >span {
        display: inline-block;
        img {
          width: 60px;
          height: 60px;
        }
        >span {
          display: inline-block;
          width: 60px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
          &.eyes {
            background-color: #1890FF;
            margin-right: 20px;
            color: #FFFFFF;
            >i {
              font-size: 22px;
            }
          }
          &.del {
            background-color: rgba(#EB5446, .1);
            color: #EB5446;
            >i {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
  .empty {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #D8D8D8;
    >span {
      font-size: 100px;
    }
    >p {
      margin-top: 10px;
    }
  }
  
  .pager {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    // :global .
  }
  
}
.my{
  width: 100%;
  margin: 0;
  padding: 0;
  height: calc(100vh - 50px);
  input{
    cursor: pointer;
  }
  .content{
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 12px;
    height: 100%;
    margin-top: 10px;
    .header{
      box-sizing: border-box;
      box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
      width: 100%;
      height: 100px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 15px 30px;
      border: 1px solid #d2d2d2;
      .hpic{
        width: 70px;
        height: 70px;
        border-radius: 100%;
        margin-right: 20px;
      }
      .pinfo{
        .pname{
          padding-left: 5px;
          color: rgba(0,0,0,0.85);
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
        }
        .email{
          padding: 5px;
          color: rgba(0,0,0,0.65);
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
        }
      }
    }
    .main{
      box-sizing: border-box;
      margin-top: 10px;
      width: 100%;
      height: calc(100% - 120px);
      display: flex;
      .mainLeft{
        width: 200px;
        margin-right: 15px;
        border-radius: 4px;
        box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
        border: 1px solid #d2d2d2;
        background-color: #ffffff;
        box-sizing: border-box;
        text-align: center;
        span{
          text-align: left;
          display: block;
          height: 40px;
          line-height: 40px;
          width: 100%;
          box-sizing: border-box;
          padding: 0 14px;
          cursor: pointer;
          color: #595959;
          i{
            color: #595959;
          }
          &.active{
            background-color: #1890FF;
            color: #FFFFFF;
            border-radius: 4px;
            i{
              color: #FFFFFF;
            }
          }
        }
      }
      .mainRight{
        flex: 1;
        .account{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .accoutHeader{
            border: 1px solid #d2d2d2;
            width: 100%;
            // height: 190px;
            // height: 35%;
            height: 200px;
            box-sizing: border-box;
            border-radius: 6px;
            margin-bottom: 12px;
            box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
            overflow: hidden;
            h3{
              height: 40px;
              line-height: 40px;
              background-color: rgba(24,144,255,0.10);
              margin: 0;
              padding-left: 15px;
              font-size: 16px;
              color: #454545;
            }
            .aItem{
              height: 40px;
              // line-height: 40px;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              box-sizing: border-box;
              padding: 0 30px;
              border-bottom: 1px solid #D2D2D2;
              .link {
                border: none;
                background: transparent;
                color: #FF5502;
                font-size: 14px;
                cursor: pointer;
                display: flex;
                align-items: center;
                em {
                  font-style: normal;
                  margin-left: 6px;
                }
              }
              >span{
                width: 32%;
                font-size: 14px;
                font-weight: 400;
                color: #595959;
                &:nth-child(1) {
                  width: 25%;
                }
                &:nth-child(2) {
                  width: 50%;
                }
                &:nth-child(3) {
                  width: 25%;
                }
              }
              .f1{
                font-weight: 500;
              }
              &.other {
                height: 60px;
                > span {
                  > span {
                    font-family: PingFangSC-Regular;
                    font-weight: Regular;
                    font-size: 14px;
                    color: #333333;
                    display: inline-block;
                    padding: 3px 5px;
                    margin-right: 10px;
                    margin-bottom: 2px;
                    border-radius: 4px;
                    background-color: #F6F6F6;
                  }
                }
              }
            }
          }
          .accountContent{
            flex: 1;
            width: 100%;
            border-radius: 6px;
            box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
            overflow: hidden;
            border: 1px solid #d2d2d2;
            h3{
              height: 40px;
              line-height: 40px;
              background-color: rgba(24,144,255,0.10);
              margin: 0;
              padding-left: 15px;
              font-size: 16px;
              color: #454545;
            }
            .aItem{
              height: 40px;
              // line-height: 40px;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              box-sizing: border-box;
              padding: 0 30px;
              border-bottom: 1px solid #D2D2D2;
              span{
                // width: 100%;
                width: 32%;
                font-size: 14px;
                font-weight: 400;
                color: #595959;
                &:nth-child(1) {
                  width: 25%;
                }
                &:nth-child(2) {
                  width: 50%;
                }
                &:nth-child(3) {
                  width: 25%;
                }
              }
              .f1{
                font-weight: 500;
              }
              
            }
          }
        }
        .msg{
          .msgTop{
            display: flex;
            span{
              height: 40px;
              line-height: 40px;
              box-sizing: border-box;
              cursor: pointer;
              margin: 0 10px;
              
              &.active{
                border-bottom: 2px solid #2a98fe;
              }
            }
          }
        }
      }
    }
  }
}
.fw500{
  font-weight: 500;
}

.modify {
  h3{
    font-weight: normal;
    font-size: 22px;
    padding: 10px;
    border-bottom: 1px solid #f6f6f6;
  }
  .inputItem{
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 0px 20px;
    >span{
      width: 110px;
      margin-right: 10px;
      text-align: left;
    }
    input{
      flex: 1;
    }
  }
}

.agreement {
  height: 500px;
  overflow-y: auto;
  padding: 20px;
  font-size: 14px;
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.favorite {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  .table-footer {
    width: 100%;
    height: 45px;
    padding: 0 5px;
    background-color: #eeeeee;
    position: absolute;
    bottom: 0;
    left:  0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    i {
      font-size: 18px;
    }
    button {
      color: #EB5446;
    }
  }
}

.new-purchase,
.new-purchase-red {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  .tabs {
    height: 40px;
    background-color: #fff;
    @extend .flex-b;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
    display: flex;
    border: 1px solid #F4F4F4;
    > span {
      display: flex;
      width: 50%;
      height: 40px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      img {
        height: 24px;
        margin-right: 10px;
      }
      &.active {
        background-color: #F4FAFF;
        border-radius: 4px;
      }
    }
  }
  .vin-con {
    .vin-list {
      border: 1px solid #F4F4F4;
      display: flex;
      height: 150px;
      margin: 10px 0;
      .vin-list-l {
        width: 25%;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        flex-direction: column;
        > span {
          line-height: 50px;
          height: 50px;
          box-sizing: border-box;
          border: 1px solid #F4F4F4;
        }
      }
      .vin-list-r {
        width: 75%;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        flex-direction: column;
        > span {
          line-height: 50px;
          height: 50px;
          box-sizing: border-box;
          border: 1px solid #F4F4F4;
        }
        .vin-check {
          background-color: #F4FAFF;
          cursor: pointer;
          color: #2E95F7;
          &.active {
            background-color:#1890FF ;
            color: #FFFFFF;
          }
          &:hover {
            box-shadow: 0px 0px 6px 0px rgba(24,144,255,0.30); 
          }
        }
      }
    }
    .input {
      width: 100%;
      height: 100px;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #D2D2D2;
      > span {
        font-family: PingFangSC-Regular;
        font-weight: Regular;
        font-size: 14px;
        color: #333333;
        display: inline-block;
        padding: 5px 8px;
        margin-right: 10px;
        margin-bottom: 5px;
        border-radius: 4px;
        background-color: #F6F6F6;
        > i {
          opacity: .4;
          cursor: pointer;
        }
      }
      > input {
        border: none;
        appearance: none;
        font-size: 16px;
        width: 25%;
      }
    }
    > h4 {
      font-family: PingFangSC-Medium;
      font-weight: Medium;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      margin: 5px 0;
    }
    >p {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #888888;
      display: flex;
      align-items: center;
      >span {
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #D9D9D9;
      }
    }
  }
  .table-list {
    margin-top: 10px;
    width: 100%;
    height: 470px;
    box-sizing: border-box;
    border: 1px solid #F4F4F4;
    border-radius: 4px;
    display: flex;
    .col {
      width: 25%;
      height: 100%;
      box-sizing: border-box;
      > span {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #F4F4F4;
        &:nth-child(1) {
          height: 50px;
          // line-height: 50px;
          text-align: center;
        }
      }
      &:nth-child(1) {
        > span {
          justify-content: flex-start;
          padding-left: 10px;
          text-align: left;
        }
      }
      .day {
        height: 350px;
        line-height: 350px;
        color: $primary-color;
        background-color: #F4FAFF;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 6px 0px rgba(24,144,255,0.30); 
        }
      }
      .hasfree {
        height: 350px;
        line-height: 350px;
        color: $primary-color;
        background-color: #F4FAFF;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 6px 0px rgba(24,144,255,0.30); 
        }
      }
      .nofree {
        background-color: #F4F4F4;
        height: 350px;
        line-height: 350px;
        font-size: 20px;
        color: #BFBFBF;
      }
      .item {
        color: $primary-color;
        background-color: #F4FAFF;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        position: relative;
        .save {
          box-sizing: border-box;
          margin-top: 5px;
          padding: 2px 10px;
          border-radius: 3px;
          font-size: 16px;
          background-color: rgba($color: #4CC803, $alpha: .1);
          color: #4CC803;
        }
        .tip {
          display: none;
        }
        &:hover {
          box-shadow: 0px 0px 6px 0px rgba(24,144,255,0.30);
          .tip {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            top: -110px;
            height: 100px;
            padding: 5px 10px 0 10px;
            box-sizing: border-box;
            background-color: rgba(#000000, .8);
            border-radius: 5px;
            color: #FFFFFF;
            z-index: 100;
            max-width: 600px;
            word-break: break-all;
            p {
              width: 500px;
              text-align: left;
              word-break: break-all;
            }
            > span {
              display: block;
            }
          }
        }  
      }
      .active {
        background-color:#1890FF ;
        color: #FFFFFF;
        .save {
          color: #F4FAFF;
          background-color: rgba(#ffffff, .1);
        }
      }
    }
  }
  .code {
    margin-top: 10px;
    @extend .flex-b;
    .code-main {
      @extend .flex-b;
      flex: 1;
      button {
        width: 100px;
      }
    }
    .code-info {
      @extend .flex-b;
      padding: 5px 0;
      width: 240px;
      .code-res {
        color: rgb(255, 50, 50);
      }
      .how {
        position: relative;
        cursor: pointer;
        text-align: right;
        color: $primary-color;
        .how-tip {
          display: none;
          width: 160%;
          position: absolute;
          top: 25px;
          left: -30%;
          border: 1px solid #E8E8E8;
          box-sizing: border-box;
          padding: 5px 10px 10px 10px;
          border-radius: 5px;
          text-align: center;
          background-color: #ffffff;
          z-index: 100;
          span {
            color: #454545;
          }
          h4 {
            text-align: left;
            border-bottom: 1px solid #E8E8E8;
            padding-bottom: 5px;
            color: #454545;
          }
          p {
            text-align: left;
            margin-top: 5px;
            color: rgba(#454545, .8);
          }
        }
        &:hover {
          .how-tip {
            display: block;
          }
        }
      }
    }
  }
  >p {
    margin: 5px 0;
    color: rgb(255, 50, 50);
  }
  .pay {
    margin-top: 10px;
    @extend .flex-b;
    height: 60px;
    .pay-l {
      box-sizing: border-box;
      padding: 10px;
      height: 100%;
      border: 1px solid rgba(#000000, .15);
      border-radius: 4px;
      flex: 1;
      margin-right: 10px;
      > p {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        > img {
          height: 15px;
        }
      }
    }
    .pay-r {
      height: 100%;
      .paybtn {
        background: #1890FF;
        border: 2px solid #1890FF;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s;
        cursor: pointer;
        .payimg {
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 0 20px;
        }
        button {
          height: 100%;
          background: #1890FF;
          border-color: #1890FF;
          position: relative;
          z-index: 10;
        }
        .payspan {
          width: 260px;
          height: 260px;
          border-radius: 50%;
          background: #1890FF;
          position: absolute;
          right: 110px;
          z-index: 9;
          transition: all 0.3s;
        }
      }
      
    }
  }
}
.new-purchase-red {
  
  .tabs {
    > span {
      &.active {
        background-color: #FFF7F3;
      }
    }
  }
  .vin-check {
    background-color: #FFF7F3 !important;
    color: #FF6018 !important;
    &.active {
      background-color:#FF6018 !important ;
      color: #FFFFFF !important;
    }
    &:hover {
      box-shadow: 0px 0px 6px 0px rgba(242, 110, 10, 0.3) !important; 
    }
  }
  .pay-r {
    .paybtn {
      background: #FF6018 !important;
      border: 2px solid #FF6018 !important;
      button {
        background: #FF6018 !important;
        border-color: #FF6018 !important;
      }
      .payspan {
        background: #FF6018 !important;
      }
    }
  }
}
.history {
  margin: 0 20px;
  .border {
    border: 1px solid #D9D9D9;
    padding-bottom: 20px;
    .top {
      background: #F3F3F3;
      height: 40px;
      line-height: 40px;
      li {
        display: inline-block;
        padding: 0 30px;
        cursor: pointer;
        &.active {
          background: #fff;
        }
      }
    }
    .tab {
      padding: 20px;
      li {
        display: inline-block;
        padding: 0 10px;
        line-height: 30px;
        border: 1px solid #F3F3F3;
        cursor: pointer;
        &.active {
          background: #1890FF;
          color: #fff;
        }
      }
    }
    .content {
      li {
        padding: 6px 20px;
      }
    }
  }
}
.coupon {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  .search {
    margin-bottom: 10px;
    >input {
      width: 200px;
    }
    >span {
      width: 32px;
      height: 32px;
      border-top: 1px solid #D9D9D9;
      border-right: 1px solid #D9D9D9;
      border-bottom: 1px solid #D9D9D9;
    }
  }
}

.message {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10);
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  .nav {
    display: flex;
    align-items: center;
    height: 40px;
    li {
      box-sizing: border-box;
      padding-bottom: 5px;
      margin: 10px 10px 0 10px;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #1890FF;
      }
    }
  }
  .list {
    width: 100%;
    height: calc(100% - 40px);
    overflow: scroll;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 50px;
    .item {
      border-radius: 4px;
      padding: 10px;
      box-sizing: border-box;
      background-color: #f6f6f6;
      margin-bottom: 10px;
      position: relative;
      h3 {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        color: #333333;
        font-family: PingFangSC, PingFangSC-Medium;
        .title {
          position: relative;
          display: inline-block;
          .dot {
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: rgb(255, 50, 50);
            border-radius: 6px;
          }
        }
        .time {
          width: 200px;
          text-align: right;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
      }
      p {
        font-weight: 400;
        color: #333330;
        font-family: PingFangSC, PingFangSC-Regular;
      }
      .detail {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 14px;
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
  .empty {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #D8D8D8;
    >span {
      font-size: 60px;
    }
    >p {
      margin-top: 10px;
    }
  }
  .pager {
    position: absolute;
    left: 10px;
    bottom: 0;
    width: calc(100% - 10px);
    background-color: #fff;
    padding-bottom: 10px;
  }
  .feedback-details {
    width: 100%;
    .info {
      box-sizing: border-box;
      margin: 10px 20px;
      min-height: 125px;
      background: #f6f6f6;
      border-radius: 4px;
      padding: 10px;
      display: flex;
      > img {
        width: 140px;
        height: 105px;
        margin-right: 10px;
      }
      .r {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #333333;
      }
    }
    .reply {
      box-sizing: border-box;
      margin: 10px 20px;
      height: 200px;
      overflow-y: scroll;
      .item {
        display: flex;
        margin-bottom: 10px;
        >img {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
        }
        .cont {
          background-color: rgba($primary-color, .1);
          padding: 10px;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          margin-right: 35px;
          > span {
            display: block;
            color: #999999;
          }
          > p{
            color: #333333;
          }
        }
        &.me {
          flex-direction: row-reverse;
          > img {
            margin-left: 10px;
            margin-right: 0px;
          }
          .cont {
            margin-left: 35px;
            margin-right: 0;
            > span {
              text-align: right;
            }
            > p {
              text-align: right;
            }
          }
        }
      }
    }
    .func {
      display: flex;
      justify-content: space-between;
      margin: 10px 20px;
      button {
        width: 230px;
        height: 40px;
      }
    }
    .supple {
      margin: 10px 20px;
      h4 {
        color: rgba(0, 0, 0, .65);
        font-weight: 400;
        margin-bottom: 10px;
      }
      textarea {
        width: 100%;
        height: 200px;
      }
      .btn {
        margin-top: 5px;
        display: flex;
        flex-direction: row-reverse;
        button {
          margin-left: 15px;
        }
      }
    }
  }
}

.paypal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 60%;
  width: 100%;
  div {
    height: 120px;
  }
}