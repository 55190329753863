// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
$Button: "button";
// 主色调
$--primary-color: $primary-color !default;
// 主色调-鼠标悬浮色
$--color-light: lighten($--primary-color, 10%) !default;
// 主色调-鼠标点击色
$--color-dark: darken($--primary-color, 10%) !default;
$--text-color-dark: rgba(0, 0, 0, 0.65);
$--disabled-color-dark: #d9d9d9;
// Disabled states
$--disabled-color: rgba(0, 0, 0, 0.25);
$--disabled-bg: #f5f5f5;
$--disabled-color-dark: #d9d9d9;

/** 辅助色 **/
// info 色调
$--color-info: $primary-color !default;
// success 色调
$--color-success: $success-color !default;
// warning 色调
$--color-warning: $warning-color !default;
// error 色调
$--color-error: $error-color !default;

.#{$Button} {
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  height: 32px;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: $--text-color-dark;
  background-color: #fff;
  border-color: $--disabled-color-dark;
  outline: 0;
  .icon-loading {
    border-left-color: $--text-color-dark;
  }
  &:hover,
  &:focus {
    color: $--color-light;
    border-color: $--color-light;
  }

  &:not([disabled]):active {
    color: $--color-dark;
    border-color: $--color-dark;
  }

  &:not([disabled]):active {
    transition: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: $--disabled-color;
    background-color: $--disabled-bg;
    border-color: $--disabled-color-dark;
    .icon-loading {
      border-left-color: $--disabled-color;
    }
  }

  &.two-chinese-chars > * {
    letter-spacing: 0.34em;
    margin-right: -0.34em;
  }
}
.#{$Button} > .icon-loading {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 2px solid rgba(0,0,0,0.85);
  border-radius: 50%;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: cssload-spin 575ms infinite linear;
}
.#{$Button} > .#{$Button}-icon + span {
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
}

/**
 *
 *  按钮类型
 *
 */
.#{$Button}-type-primary {
  color: #fff;
  background-color: $--primary-color;
  border-color: $--primary-color;
  .icon-loading {
    border-left-color: #fff;
  }
  &:hover,
  &:focus {
    color: #fff;
    background-color: $--color-light;
    border-color: $--color-light;
  }

  &:not([disabled]):active {
    color: #fff;
    background-color: $--color-dark;
    border-color: $--color-dark;
  }

  &:disabled {
    color: $--disabled-color;
    background-color: $--disabled-bg;
    border-color: $--disabled-color-dark;
    .icon-loading {
      border-left-color: $--disabled-color;
    }
  }

  &.#{$Button}-ghost {
    color: $--primary-color;
    background-color: transparent;
    border-color: $--primary-color;
    .icon-loading {
      border-left-color: $--primary-color;
    }
    &:hover,
    &:focus {
      color: $--color-light;
      background-color: transparent;
      border-color: $--color-light;
    }

    &:not([disabled]):active {
      color: $--color-dark;
      background-color: transparent;
      border-color: $--color-dark;
    }
  }
}

.#{$Button}-type-dashed {
  border-style: dashed;
}

.#{$Button}-type-text {
  border: none;
  background-color: transparent;
}

.#{$Button}-type-danger {
  color: $--color-error;
  background-color: #f5f5f5;
  .icon-loading {
    border-left-color: $--color-error;
  }
  &:hover,
  &:focus {
    color: #fff;
    background-color: $--color-error;
    border-color: $--color-error;
  }

  &:not([disabled]):active {
    color: #fff;
    background-color: darken($--color-error, 20%);
    border-color: darken($--color-error, 20%);
  }

  &:disabled {
    color: $--disabled-color;
    background-color: transparent;
    border-color: $--disabled-color-dark;
    .icon-loading {
      border-left-color: $--disabled-color;
    }
  }

  &.#{$Button}-ghost {
    color: $--color-error;
    background-color: transparent;
    border-color: $--color-error;
    &:hover,
    &:focus {
      color: lighten($--color-error, 20%);
      background-color: transparent;
      border-color: lighten($--color-error, 20%);
    }

    &:not([disabled]):active {
      color: darken($--color-error, 20%);
      background-color: transparent;
      border-color: darken($--color-error, 20%);
    }
  }
}

.#{$Button}-ghost {
  background: transparent;
  border-color: #fff;
  color: #fff;
  .icon-loading {
    border-left-color: #fff;
  }
  &:hover,
  &:focus {
    color: $--color-light;
    background-color: transparent;
    border-color: $--color-light;
  }

  &:not([disabled]):active {
    color: $--color-dark;
    background-color: transparent;
    border-color: $--color-dark;
  }
}

/**
 *
 *  按钮尺寸
 *
 */

.#{$Button}-size-small {
  padding: 0 7px;
  font-size: 13px;
  border-radius: 4px;
  height: 24px;
}

.#{$Button}-size-large {
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
  height: 40px;
}

.#{$Button}-long {
  width: 100%;
}

/**
 *
 *  按钮形状
 *
 */

.#{$Button}-shape-circle {
  width: 32px;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
  height: 32px;

  &.#{$Button}-size-small {
    width: 24px;
    padding: 0;
    font-size: 14px;
    border-radius: 50%;
    height: 24px;
  }

  &.#{$Button}-size-large {
    width: 40px;
    padding: 0;
    font-size: 18px;
    border-radius: 50%;
    height: 40px;
  }
}

/**
 *
 *  按钮状态
 *
 */
.#{$Button}-loading {
  pointer-events: none;
  opacity: 0.6;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
