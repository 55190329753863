// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.lite {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  .svg-box {
    position: relative;
    img {
      width: 100%;
    }
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      rect {
        cursor: pointer;
        &.active {
          stroke: #3385ff;
          stroke-width: 4px;
          stroke-dasharray: 0;
          stroke-linejoin: round;
        }
      }
      
    }
  }
  .img-tools {
    position: absolute;
    top: 10px;
    right: 10px;
    span {
      display: block;
      width: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15); 
      margin-bottom: 16px;
      i {
        cursor: pointer;
        display: block;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      u {
        display: block;
        height: 1px;
        overflow: hidden;
        background: #D2D2D2;
      }
    }
  }
}

.standard {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  .svg-box {
    position: relative;
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text {
        cursor: pointer;
        font-weight: bold;
        &::selection {
          background: transparent;
        }
      }
    }
  }
  .img-tools {
    position: absolute;
    top: 10px;
    right: 10px;
    span {
      display: block;
      width: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15); 
      margin-bottom: 16px;
      i {
        cursor: pointer;
        display: block;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      u {
        display: block;
        height: 1px;
        overflow: hidden;
        background: #D2D2D2;
      }
    }
  }
}