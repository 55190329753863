// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
$checkboxWarpPrefixCls: "checkbox";
$checkboxInnerPrefixCls: "#{$checkboxWarpPrefixCls}-inner";

$color-unchecked: #ffffff;
$color-unchecked-border: #d9d9d9;
$color-checked: #1890ff;
$color-disabled: #f3f3f3;
$color-checked-disabled-border: #cccccc;


.#{$checkboxWarpPrefixCls} {
  position: relative;
  display: inline-block;
  line-height: unset;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  user-select: none;
  cursor: pointer;

  &:after {
    border-color: $color-disabled;
    border-collapse: separate;
    animation-name: none;
  }

  &:hover .#{$checkboxInnerPrefixCls},
  .#{$checkboxWarpPrefixCls}-input:focus + .#{$checkboxInnerPrefixCls} {
    border-color: $color-checked;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  }

  .#{$checkboxInnerPrefixCls} {
    &:after {
      position: absolute;
      top: 50%;
      left: 21%;
      display: table;
      width: 0.3em;
      height: 0.6em;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: " ";
    }

    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 1em;
    height: 1em;
    background-color: $color-unchecked;
    border: 1px solid $color-unchecked-border;
    border-radius: 0.2em;
    border-collapse: separate;
    transition: all 0.3s;
    transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), border-color 0.15s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  .#{$checkboxWarpPrefixCls}-input {
    position: absolute;
    left: 0;
    z-index: 9999;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

/* Checked state */
.#{$checkboxWarpPrefixCls}-checked {
  &:after {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 100%;
    height: 100%;
    border: 0.1em solid #1890ff;
    border-radius: 0.2em;
    animation: CheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: both;
    content: "";
  }

  &:hover {
    .#{$checkboxInnerPrefixCls} {
      border-color: $color-checked;
    }
  }

  &.#{$checkboxWarpPrefixCls}-indeterminate {
    .#{$checkboxInnerPrefixCls}:after {
      content: "";
      width: 0.4em;
      height: 0;
      transform: scale(1);
      position: absolute;
      left: 25%;
      top: 45%;
      box-sizing: border-box;
      border-width: 0.15em;
    }
  }

  .#{$checkboxInnerPrefixCls} {
    border-color: $color-checked;
    background-color: $color-checked;

    &:after {
      position: absolute;
      top: 48%;
      left: 21%;
      display: table;
      border: 0.13em solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }
}

/* Disabled state */
.#{$checkboxWarpPrefixCls}-disabled,
.#{$checkboxWarpPrefixCls}-readonly {
  /* 选中且禁用 */
  &.#{$checkboxWarpPrefixCls}-checked {
    &:hover {
      .#{$checkboxInnerPrefixCls} {
        border-color: $color-unchecked-border;
      }
    }

    .#{$checkboxInnerPrefixCls} {
      background-color: $color-disabled;
      border-color: $color-unchecked-border;

      &:after {
        animation-name: none;
        border-color: $color-checked-disabled-border;
      }
    }
  }

  &:hover {
    .#{$checkboxInnerPrefixCls} {
      border-color: $color-unchecked-border;
    }
  }

  .#{$checkboxInnerPrefixCls} {
    border-color: $color-unchecked-border;
    background-color: $color-disabled;
    &:after {
      animation-name: none;
      border-color: $color-disabled;
    }
  }

  .#{$checkboxWarpPrefixCls}-input {
    cursor: default;
  }
}

@keyframes CheckboxEffect {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.5;
    visibility: visible;
  }

  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
    visibility: hidden;
  }
}

.checkbox-label {
  user-select: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  min-height: 18px;
  line-height: 18px;
}
.checkbox-wrap {
  margin-right: 8px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    .#{$checkboxWarpPrefixCls}:not(.#{$checkboxWarpPrefixCls}-disabled) .#{$checkboxInnerPrefixCls} {
      border-color: $color-checked;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
    }
  }
}