// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.invite {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 400px;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.08);
  border-radius: 8px;
  z-index: 9999;
  .tab {
    h6 {
      padding: 20px 0 0 20px;
      font-size: 16px;
    }
    ul {
      display: flex;
      box-shadow: 2px 2px 4px 0 rgba(48, 51, 55, 0.07);
      li {
        flex: 1;
        text-align: center;
        height: 40px;
        cursor: pointer;
        span {
          line-height: 40px;
          box-sizing: border-box;
          display: inline-block;
          position: relative;
          .number {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #FF0000;
            border: 1px solid #fff;
            position: absolute;
            top: 4px;
            right: -10px;
            color: #fff;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            font-style: normal;
          }
        }
        &.active {
          span {
            color: #1890FF;
            border-bottom: 2px solid #1890FF;
          }
        }
      }
    }
    
  }
  .content {
    padding: 30px 20px 20px;
    height: 240px;
    overflow-y: auto;
    .input {
      margin: 30px 0 50px;
      position: relative;
      input {
        width: 100%;
        height: 40px;
        border: 1px solid #1890FF;
        border-radius: 4px;
        padding: 0 6px;
        box-sizing: border-box;
      }
      p {
        font-size: 12px;
        color: #999999;
        margin-top: 4px;
        line-height: 20px;
        em {
          font-style: normal;
          color: #1890FF;
        }
      }
      i {
        position: absolute;
        top: 11px;
        right: 10px;
        color: #E8E8E8;
      }
    }
    button.submit {
      width: 100%;
      height: 40px;
      background: #1890FF;
      border-radius: 4px;
      border: none;
      color: #fff;
      cursor: pointer;
      &:disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
    .p {
      text-align: center;
      font-size: 12px;
      color: #999999;
      line-height: 26px;
    }
    .table {
      width: 100%;
      font-size: 12px;
      max-height: 164px;
      overflow-y: auto;
      table {
        width: 100%;
        font-size: 12px;
      }
      th {
        border-bottom: 1px solid #E8E8E8;
        background: #FAFAFA;
        text-align: left;
        color: #999999;
        line-height: 32px;
        padding: 0 4px;
      }
      td {
        border-bottom: 1px solid #E8E8E8;
        line-height: 32px;
        padding: 0 4px;
        button {
          cursor: pointer;
          font-size: 14px;
          color: #fff;
          line-height: 14px;
          border: none;
          background: #1890ff;
          padding: 4px 6px;
          border-radius: 4px;
          margin-bottom: 4px;
        }
      }
    }
  }
} 

.package {
  width: 90%;
  font-size: 12px;
  margin: 0px auto 10px;
  border-collapse: collapse;
  border: none;
  th {
    border-bottom: 1px solid #E8E8E8;
    background: #FAFAFA;
    text-align: left;
    color: #999999;
    line-height: 32px;
    padding: 0 4px;
  }
  td {
    border: 1px solid #E8E8E8;
    line-height: 32px;
    padding: 0 4px;
    button {
      cursor: pointer;
      font-size: 14px;
      color: #fff;
      line-height: 14px;
      border: none;
      background: #1890ff;
      padding: 4px 6px;
      border-radius: 4px;
      margin-bottom: 4px;
    }
  }
}

.invited {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 400px;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.08);
  border-radius: 8px;
  z-index: 9999;
  .content {
    width: 220px;
    margin: 20px auto;
    text-align: center;
    i {
      font-size: 40px;
      color: #999999;
    }
    h6 {
      font-size: 16px;
      color: #333333;
    }
    p {
      color: #999999;
      font-size: 14px;
      line-height: 30px;
    }
    button {
      width: 100%;
      height: 40px;
      background: #1890FF;
      border-radius: 4px;
      border: none;
      color: #fff;
      cursor: pointer;
      margin: 50px 0 10px;
    }
  }
}

.package {
  p {
    color: #999999;
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    em {
      color: #000;
      font-style: normal;
    }
  }
  .expires {
    background: #f4faff;
    border-radius: 4px;
    line-height: 40px;
    margin: 50px 0 0;
    font-size: 14px;
    text-align: center;
    em {
      color: #1890FF;
      font-style: normal;
    }
  }
  button {
    width: 100%;
    height: 40px;
    background: #1890FF;
    border-radius: 4px;
    border: none;
    color: #fff;
    cursor: pointer;
    margin: 50px 0 10px;
  }
}