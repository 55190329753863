// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.part-list-cps {
  margin-top: 12px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  .part-cps-lf {
    box-sizing: border-box;
    padding: 15px 0px 15px 15px;
    border: 1px solid #d2d2d2;
    width: 250px;
  }
  .lf-title {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    .lf-title-arraw {
      margin-left: 4px;
    }
  }
  .price-con {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .price-input-wrap {
    height: 32px;
    width: 70px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding: 5px 10px 5px 16px;
    .price-icon {
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #777777;
      line-height: 14px;
    }
    input {
      border: none;
      outline: none;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #777777;
      line-height: 20px;
      width: 44px;
      box-sizing: border-box;
    }
  }
  .arrow-rt-btn {
    border-radius: 2px;
    cursor: pointer;
  }
  .tmpl-wrap {
    margin-top: 20px;
  }
  .tmpl-con {
    display: block;
    max-height: 250px;
    overflow-y: scroll;
    &.tmpl-hide {
      display: none;
    }
  }
  .menu-item {
    margin-bottom: 15px;
    display: block;
    &.hide {
      display: none;
    }
  }
  .is-more {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #1890ff;
    line-height: 22px;
    cursor: pointer;
  }
  .part-cps-rt {
    width: calc(100% - 262px);
    box-sizing: border-box;
    margin-left: 12px;
  }
  .part-cps-rt-head {
    width: 100%;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }
  .part-list-total {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #777777;
    line-height: 20px;
    .but {
      width: 80px;
      height: 30px;
      line-height: 30px;
      background: #1890FF;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .part-list-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    box-sizing: border-box;
    border: 1px solid #d2d2d2;
  }
  .part-list-no-data, .part-list-loading {
    height: 600px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d2d2d2;
  }
  .part-list-page-wrap {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .part-list-page-rt {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #000000;
    line-height: 20px;
    .size-input-wrap {
      background: #ffffff;
      border: 1px solid rgba(0,0,0,0.15);
      border-radius: 3px;
      width: 70px;
      height: 24px;
      box-sizing: border-box;
      padding: 1px 3px;
      margin-left: 15px;
      input {
        border: none;
        outline: none;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #000;
        line-height: 22px;
        width: 44px;
        box-sizing: border-box;
      }
    }
  }
  
}

.part-item-wrap {
  margin-top: 20px;
  margin-left: 20px;
  width: 228px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  box-shadow: 0px 2px 6px 0px rgba(24,144,255,0.10); 
}

.part-item-img-wrap {
  width: 100%;
  height: 228px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  img {
    max-width: 228px;
    max-height: 100%;
    width:auto;
    height:auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
  }
  .part-item-brand {
    width: 100%;
    height: 24px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 24px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
  }

  &:hover {
    span {
      display: inline-block !important;
    }
  }
  span {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 34px;
    color: #1890FF;
    width: 34px;
    height: 34px;
    line-height: 34px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: none !important;
  }
  
}
.part-item-info-wrap {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.part-item-des {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #333330;
  line-height: 21px;
  height: 42px;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  a {
    color: #333330;
  }
}
.part-item-info-midl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.part-item-oe {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333330;
  line-height: 14px;
}
.part-item-location {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333330;
  line-height: 12px;
}
.part-item-price-wrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;
}
.part-item-price {
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #f45f00;
  line-height: 28px;
  i {
    font-size: 14px;
    color: #1890FF;
    margin-left: 6px;
    cursor: pointer;
    vertical-align: 2px;
  }
}
.part-item-price-pop {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #a8a8a8;
  line-height: 12px;
  margin-left: 2px;
  img {
    height: 24px;
  }
}
.part-item-btn {
  cursor: pointer;
  margin-top: 10px;
  height: 38px;
  width: 100%;
  border: 1px solid #1890FF;
  background: #1890FF;
  border-radius: 6px;
  box-shadow: 0px 5px 10px 0px rgba(24,144,255,0.20);
  display: flex;
  align-items: center;
  overflow: hidden;
  span {
    width: 80px;
    height: 100%;
    text-align: center;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 16px;
    }
  }
  i {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-left: 26px solid #fff;
    border-bottom: 19px solid transparent;
  }
  em {
    text-align: center;
    color: #fff;
    font-style: normal;
    flex: 1;
    text-align: center;
  }
}

.list {
  display: flex;
  border-bottom: 1px dotted #e1e1e1;
  padding: 10px 0;
  width: 98%;
  margin: 0 auto;
  align-items: center;
  .img {
    width: 110px;
    height: 110px;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
    img {
      max-width: 100%;
      max-height: 100%;
      width:auto;
      height:auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%,-50%,0);
    }
    .part-item-brand {
      width: 100%;
      height: 24px;
      background: rgba(0, 0, 0, 0.3);
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 24px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 1;
    }

    &:hover {
      span {
        display: inline-block !important;
      }
    }
    span {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 34px;
      color: #1890FF;
      width: 34px;
      height: 34px;
      line-height: 34px;
      background: #fff;
      border-radius: 50%;
      cursor: pointer;
      display: none !important;
    }
  }
  .info {
    flex: 1;
    .part-item-des {
      height: auto;
      font-size: 14px;
      font-weight: bold;
    }
    .part-item-price-wrap {
      margin: 6px 0;
    }
    .part-item-info-midl {
      justify-content: flex-start;
      .part-item-location {
        margin-left: 10px;
      }
    }
  }
  .part-item-btn {
    width: 160px;
  }
}