// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
$InputPrefixCls: "input";
$InputGroupPrefixCls: "#{$InputPrefixCls}-group";
$AffixWrapper: "#{$InputPrefixCls}-affix-wrapper";

$color-unchecked: #ffffff;
$color-unchecked-border: #d9d9d9;
$color-checked: $primary-color;
$color-disabled: #f3f3f3;
$color-checked-disabled-border: #cccccc;
$--placeholder-color: #bfbfbf;
$--disabled-color: rgba(0, 0, 0, 0.25);
$--disabled-bg: #f5f5f5;
$--disabled-color-dark: #d9d9d9;

.#{$InputPrefixCls} {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: $color-unchecked;
  background-image: none;
  border: 1px solid $color-unchecked-border;
  border-radius: 4px;
  transition: all 0.3s;
  &.#{$InputPrefixCls}-large {
    height: 40px;
    padding: 7px 11px;
  }
  &.#{$InputPrefixCls}-small {
    height: 28px;
    padding: 2px 11px;
  }
  &:hover,
  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
    z-index: 1;
  }

  &.#{$InputPrefixCls}-disabled {
    background-color: $--disabled-bg;
    border-color: $--disabled-color-dark;
    color: $--disabled-color;
    cursor: not-allowed;
  }
}

.#{$InputPrefixCls}-clear {
  cursor: pointer;
  color: #999;

  &:hover {
    color: #333;
  }
}

/** 前置/后置 **/
.#{$InputGroupPrefixCls}-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.#{$InputGroupPrefixCls} {
  font-feature-settings: "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #393939;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;

  .#{$InputPrefixCls},
  .#{$AffixWrapper} {
    display: table-cell;
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }

  & > .#{$InputPrefixCls}:first-child,
  & > .#{$InputPrefixCls}-addon:first-child,
  & > .#{$AffixWrapper}:first-child .#{$InputPrefixCls} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > .#{$InputPrefixCls}:last-child,
  & > .#{$InputPrefixCls}-addon:last-child,
  & > .#{$AffixWrapper}:last-child .#{$InputPrefixCls} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.#{$InputPrefixCls}-addon {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;

  &:first-child {
    border-right: 0;
  }

  &:last-child {
    border-left: 0;
  }

  .xy-select {
    margin: -5px -11px;

    .xy-select-box {
      margin: -1px;
      background-color: inherit;
      border: 1px solid transparent;
    }
  }
}

.#{$InputPrefixCls}-addon:not(:first-child):not(:last-child),
.#{$InputGroupPrefixCls}-wrapper:not(:first-child):not(:last-child),
.#{$InputGroupPrefixCls} > .#{$InputPrefixCls}:not(:first-child):not(:last-child),
.#{$InputGroupPrefixCls} > .#{$AffixWrapper}:not(:first-child):not(:last-child) > .#{$InputPrefixCls} {
  border-radius: 0;
}

.#{$InputGroupPrefixCls}.#{$InputGroupPrefixCls}-compact {
  display: block;
  zoom: 1;

  &:after {
    display: table;
    content: "";
    clear: both;
  }

  &:before {
    display: table;
    content: "";
  }

  & > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;

    .xy-select-box {
      border-radius: 0;
    }
  }

  & > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }

  & .#{$InputPrefixCls} {
    float: none;
  }

  & > *:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    .xy-select-box {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  & > *:last-child {
    border-right-width: 1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .xy-select-box {
      border-right-width: 1px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

/** 前缀后缀 **/
.#{$AffixWrapper} {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;

  .#{$InputPrefixCls} {
    position: relative;
    text-align: inherit;
    min-height: 100%;

    &:not(:first-child) {
      padding-left: 30px;
    }
    &:not(:last-child) {
      padding-right: 30px;
    }
  }

  .#{$InputPrefixCls}-prefix,
  .#{$InputPrefixCls}-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0;
    transform: translateY(-50%);
  }

  .#{$InputPrefixCls}-prefix {
    left: 12px;
  }
  .#{$InputPrefixCls}-suffix {
    right: 12px;
  }
}

/** rest**/
input {
  // Firefox
  &::-moz-placeholder {
    // #bfbfbf
    color: $--placeholder-color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $--placeholder-color;
  }
  // Safari and Chrome
  &::-webkit-input-placeholder {
    color: $--placeholder-color;
  }
}

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none;
}
