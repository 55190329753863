// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.main-box {
  margin: 0 20px;
}

.bg {
  background: #F3F3F3;
  border-bottom: 1px solid #1890FF;
  box-sizing: border-box;
  height: 40px;
  .header {
    padding: 0;
    height: 40px;
    .login {
      font-size: 14px;
      span {
        color: #000;
        line-height: 20px;
      }
      button {
        height: 30px;
        font-size: 14px;
      }
      a {
        color: #000;
        font-size: 14px;
      }
      &.msg {
        background: #fff;
        i {
          color: #2b99ff;
        }
      }
    }
  }
}
.header {
  margin: 0 20px;
  display: flex;
  align-items: center;
  .logo{
    width: 50%;
    display: flex;
    align-items: center;
    i {
      color: $primary-color;
      margin-right: 6px;
    }
    img {
      width: 116px;
      display: block;
    }
    .min {
      width: 80px;
    }
    span {
      margin-left: 20px;
      color: #000;
      cursor: pointer;
      background: #fff;
      display: block;
      height: 38px;
      line-height: 38px;
      padding: 0 6px;
      border-radius: 4px 4px 0 0;
      white-space: nowrap;
      &.active {
        color: #fff;
        background: #1890FF;
      }
    }
  }
  .tobuy {
    display: inline-block;
    padding: 5px 15px;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(96deg, #FF8447 2.62%, #F33A3C 96.92%);
    border-radius: 13px;
    position: relative;
    margin-right: 15px;
    cursor: pointer;
    .icon {
      position: absolute;
      bottom: -4px;
      left: -2px;
    }
  }

  .login {
    width: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      color: #454545;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      &.msg {
        background: #2b99ff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;
        i {
          color: #454545;
          line-height: 24px;
          font-size: 16px;
        }
      }
    }
    u {
      margin: 0 10px;
      color: #bdb8b8;
    }
    .freecount {
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: 30px;
      border: 1px solid #FF6018;
      margin-right: 10px;
      background: #fff;
      em {
        display: block;
        width: 30px;
        text-align: center;
        line-height: 30px;
        border-right: 1px solid #FF6018;
        background: #feefea;
        color: #FF6018;
        border-radius: 5px;
        font-style: normal;
        font-size: 24px;
      }
      p {
        line-height: 15px;
        width: 80px;
        color: #FF6018;
        padding: 0 6px;
        font-size: 12px;
      }
    }
    button {
      height: 30px;
      padding: 0 4px;
      color: #fff;
      border-radius: 5px;
      display: inline-block;
      border: none;
      background: $primary-color;
      cursor: pointer;
      &.hollow {
        border: 1px solid $primary-color;
        background: transparent;
        margin-left: 10px;
        color: #2b99ff;
      }

      &.tried {
        border: 1px solid #FF6018;
        background: #ffffff;
        color: #FF6018;
        width: auto;
        padding: 0 8px;
        margin-right: 10px;
        cursor: default;
      }
      &.red {
        border: 1px solid #FF6018;
        background: #FF6018;
        margin-right: 10px;
        position: relative;
        .number {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #FF0000;
          border: 1px solid #fff;
          position: absolute;
          top: -7px;
          right: -17px;
          color: #fff;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
        }
      }
    }
    a {
      color: #454545;
    }
    i {
      font-size: 14px;
    }
  }
}
.agreement {
  height: 500px;
  overflow-y: auto;
  padding: 20px;
  h6 {
    font-size: 14px;
    color: #1890FF;
    margin-top: 12px;
  }
}
.footer {
  text-align: center;
  font-size: 12px;
  color: #8E8E8E;
  a {
    color: #8E8E8E;
    text-decoration: underline;
  }
  span {
    color: #8E8E8E;
    text-decoration: underline;
    cursor: pointer;
  }
}
.contact {
  textarea {
    width: 92%;
    display: block;
    margin: 10px auto;
    border: none;
    background: #F7F9FB;
    padding: 6px;
    border-radius: 4px;
  }
  .email {
    width: 92%;
    margin: 10px auto;
    padding: 6px;
    background: #F7F9FB;
    border-radius: 4px;
  }
  .but {
    text-align: right;
    padding: 10px;
    button {
      height: 40px;
      background: #1890FF;
      border-radius: 4px;
      font-size: 16px;
      color: #FFFFFF;
      border: none;
      padding: 0 10px;
      cursor: pointer;
    }
  }
}
.code {
  display: flex;
  padding: 20px;
  div {
    width: 50%;
    text-align: center;
    img {
      max-width: 80%;
    }
  }
}
.search {
  display: flex;
  align-items: center;
  height: 60px;
  .logo {
    width: 230px;
  }
  .box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    width: 60%;
    .input {
      flex: 1;
      border: 1px solid $primary-color;
      border-radius: 6px 0 0 6px;
      height: 40px;
      padding-left: 10px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      .selected {
        height: 24px;
        background: #e0f0ff;
        padding: 0 10px;
        margin-right: 10px;
        line-height: 24px;
        cursor: pointer;
        transition: all 2s;
        border-radius: 4px;
        .img {
          display: flex;
          align-items: center;
          img {
            height: 24px;
          }
        }
        i {
          opacity: .2;
          margin-left: 4px;
        }
      }
      .in {
        flex: 1;
        position: relative;
        .tips {
          padding: 4px 8px;
          background: #F5222D;
          position: absolute;
          bottom: 34px;
          left: 4px;
          color: #fff;
          border-radius: 4px;
          .down {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 10px solid #F5222D;
            position: absolute;
            bottom: -8px;
            left: 10px;
          }
        }
        input {
          width: 100%;
          border: none;
          appearance: none;
          font-size: 16px;
          &::-webkit-input-placeholder {
            color: #b8ddff;
          }
        }
        i {
          color: #1890FF;
          font-size: 24px;
          cursor: pointer;
          position: absolute;
          top: -3px;
          right: 10px;
          background: #fff;
        }
      }
    }
    
    button {
      width: 64px;
      height: 40px;
      border: none;
      background: $primary-color;
      border-radius: 0 6px 6px 0;
      cursor: pointer;
      i {
        font-size: 24px;
        color: #fff;
      }
    }
    .vinHistory {
      width: 100%;
      background: #fff;
      min-height: 100px;
      border-radius: 4px;
      box-shadow: 4px 10px 19px 0px rgba(50, 138, 219, 0.3);
      position: absolute;
      top: 40px;
      left: 0px;
      z-index: 999;
      .item {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        border-bottom: 1px solid #e0f0ff;
        cursor: pointer;
      }
    }
    .search-content {
      width: 100%;
      background: #fff;
      border-radius: 4px;
      box-shadow: 4px 10px 19px 0px rgba(50, 138, 219, 0.3);
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 999;
      .h {
        padding: 0 10px;
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        line-height: 40px;
        border-bottom: 1px solid #d7e6f5;
        position: relative;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        img {
          height: 38px;
        }
        h6 {
          font-size: 14px;
          font-weight: normal;
        }
        ul {
          display: flex;
          li {
            margin-right: 10px;
            cursor: pointer;
            font-size: 14px;
            
            &:hover {
              color: #2b99ff;
            }
          }
        }
        .cancel {
          position: absolute;
          right: 10px;
          cursor: pointer;
          span {
            font-size: 12px;
          }
          
        }
      }
      .b {
        height: 360px;
      }
      .group {
        display: flex;
        border-bottom: 1px solid #d7e6f5;
        height: 100%;
        h5 {
          width: 20%;
          background: #e0f0ff;
          text-align: center;
          font-size: 16px;
          padding-top: 20px;
        }
        .item {
          width: 80%;
          box-sizing: border-box;
          max-height: 100%;
          overflow-y: auto;
          span {
            display: inline-block;
            line-height: 20px;
            padding: 10px 16px;
            box-sizing: border-box;
            cursor: pointer;
            width: 33%;
            font-size: 14px;
            &:hover {
              color: $primary-color;
            }
            &.special {
              width: 100%;
              &:nth-child(even) {
                background: #F9FBFF;
              }
            }
          }
        }
      }
    }
    .ocr {
      width: 100%;
      background: #fff;
      border-radius: 4px;
      box-shadow: 4px 10px 19px 0px rgba(50, 138, 219, 0.3);
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 999;
      .upload {
        min-height: 200px;
        text-align: center;
        cursor: pointer;
        span {
          font-size: 50px;
          color: $primary-color;
          margin: 30px auto 10px;
          display: inline-block;
        }
        button {
          height: 40px;
          width: auto;
          line-height: 40px;
          padding: 0 12px;
          border-radius: 4px;
          background: $primary-color;
          color: #fff;
          border: 0;
          font-size: 18px;
          margin: 20px auto 0;
          display: block;
          cursor: pointer;
        }
      }
      .ocrImg {
        max-height: 200px;
        text-align: center;
        background: #e0f0ff;
        padding: 10px 0;
        cursor: pointer;
        img {
          max-height: 200px;
          max-width: 96%;
        }
      }
      .uploaded {
        input {
          border: 1px solid #d2e8fc;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          padding: 0 16px;
          width: 100%;
          box-sizing: border-box;
          letter-spacing: 16px;
        }
        button {
          width: 96%;
          height: 40px;
          line-height: 40px;
          background: $primary-color;
          color: #fff;
          border: 0;
          font-size: 18px;
          margin: 10px auto 0;
          display: block;
          cursor: pointer;
          border-radius: 4px;
        }
        p {
          text-align: center;
          padding: 10px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}

.crumb {
  width: 100%;
  height: 42px;
  line-height: 42px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex: 1;
  img {
    height: 38px;
  }
  h6 {
    font-size: 14px;
    font-weight: normal;
  }
  ul {
    display: flex;
    li {
      margin-right: 10px;
      cursor: pointer;
      font-size: 14px;
      
      &.active {
        color: #2b99ff;
      }
    }
  }
}

.crumbNew {
  height: 40px;
  line-height: 20px;
  display: flex;
  align-items: center;
  background: #EAF5FF;
  margin-bottom: 8px;
  img {
    height: 40px;
  }
  i {
    margin: 0 4px;
  }
  span {
    color: #1890FF;
    cursor: pointer;
  }
}

.watchlist {
  height: 30px;
  color: #fff;
  border-radius: 5px;
  border: none;
  background: $primary-color;
  padding: 0 10px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 56px;
}
.watch {
  min-height: 140px;
  img {
    height: 60px;
  }
  table {
    border-spacing: 0px;
    width: 98%;
    margin: 0 auto;
    box-sizing: border-box;
    td {
      padding: 10px;
      border-bottom: 1px solid #f0f0f0;
      button {
        width: 50px;
        height: 30px;
        border: none;
        background: $primary-color;
        border-radius: 4px;
        cursor: pointer;
        i {
          color: #fff;
        }
      }
    }
  }
  .table-header {
    background: #FAFAFA;
    margin-top: 10px;
    th {
      font-weight: normal;
      font-size: 12px;
      line-height: 40px;
      text-align: left;
      border-bottom: 1px solid #E8E8E8;
      padding: 0 10px;
    }
  }
  .scroll {
    max-height: 400px;
    overflow-y: auto;
  }
}

.select-config {
  padding: 0 20px 20px;
  .select-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    img {
      height: 50px;
    }
  }
  table {
    background: #E8E8E8;
    border-spacing: 1px;
    width: 100%;
    tbody {
      max-height: 400px;
      overflow-y: auto;
    }
    th {
      background: rgb(235, 234, 234);
      padding: 4px;
      text-align: left;
    }
    td {
      background: #fff;
      padding: 4px;
      cursor: pointer;
    }
  }
}