// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.vin {
  display: flex;
  margin: 0 20px 0;
  &.no-mg {
    margin: 0
  }
  .l {
    width: 40%;
  }
  .r {
    width: 60%;
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;
    .model-img {
      position: absolute;
      right: 20px;
      bottom: 10px;
      width: 160px;
    }
  }
  .box {
    border: 1px solid #e8e8e8;
    .box-top {
      height: 46px;
      background: #ebf5ff;
      display: flex;
      align-items: center;
      padding: 0 10px;
      justify-content: space-between;
      border-bottom: 1px solid #dbe5ee;
      box-sizing: border-box;
      .box-h {
        width: 200px;
        display: flex;
        h2 {
          cursor: pointer;
        }
      }
      
      h2 {
        font-size: 14px;
        font-weight: normal;
        color: #777777;
        margin-right: 12px;
        &.active {
          color: #1890FF;
        }
      }
      .box-top-tools {
        display: flex;
        align-items: center;
      }
      
      button {
        height: 30px;
        line-height: 30px;
        padding: 0 4px;
        border: 1px solid #D9D9D9;
        background: #fff;
        cursor: pointer;
        font-size: 14px;
        i {
          font-size: 14px;
        }
        &.other,
        &.other-r {
          margin-left: 10px;
          padding: 0 6px;
          i {
            margin-right: 4px;
          }
        }
        &.other-r {
          margin-right: 10px;
        }
        &.active {
          border-color: #2a98fe;
        }
      }
      em {
        font-style: normal;
        font-size: 14px;
        border: solid #D9D9D9;
        border-width: 1px 0;
        background: #fff;
        line-height: 28px;
        padding: 0 4px;
      }
    }
    .box-content,
    .box-content-h {
      height: calc(100vh - 200px);
      overflow-y: auto;
      .group {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 140px;
          height: 140px;
          box-sizing: border-box;
          border: 1px solid #D2D2D2;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 10px 0 0 10px;
          border-radius: 3px;
          cursor: pointer;
          position: relative;
          .name {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            background: rgba($color: #000000, $alpha: .7);
            display: none;
            color: #fff;
            max-height: 100%;
            overflow-y: auto;
            width: 100%;
            padding: 4px;
            box-sizing: border-box;
          }
          &:hover {
            .name {
              display: block;
            }
          }
          div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($color: #ffffff, $alpha: .4);
          }
          &.small {
            width: 110px;
            height: 110px;
            position: relative;
            img {
              width: 50px;
              height: 50px;
            }
          }
          &.active {
            border-color: #2a98fe;
          }
          img {
            height: 100%;
            max-width: 100%;
          }
          p {
            text-align: center;
            padding: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            background: rgba($color: #ffffff, $alpha: .8);
            width: 100%;
            display: block;
          }
          &.filter {
            p {
              color: #f5222d;
            }
          }
        }
      }
      .groupList {
        li {
          box-sizing: border-box;
          border-bottom: 1px solid #dbe5ee;
          font-size: 14px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          padding: 6px 0;
          span {
            padding: 0 10px;
          }
          
          &.active {
            background: #EBF5FF;
            &:hover {
              p,
              span {
                color: #454545;
              }
            }
          }
          img {
            height: 40px;
            border: 1px solid #E0E0E0;
          }
          p {
            padding: 0;
            flex: 1;
          }
          &.filter {
            p,
            span {
              color: #f5222d;
            }
          }
          &:hover {
            p,
            span {
              color: #1890FF;
            }
          }
        }
      }
      .detail {
        height: 100%;
        padding-top: 16px;
        box-sizing: border-box;
        td {
          cursor: pointer;
          img {
            width: 90%;
          }
        }
        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 0 18px;
          line-height: 30px;
          &:hover {
            background: #F9FBFF;
          }
          label {
            width: 260px;
            color: #333;
          }
          span {
            color: #777;
          }
        }
        .upkeep {
          margin-bottom: 10px;
          h5 {
            margin: 0px 0 4px 10px;
            font-weight: normal;
            border-left: 4px solid #1688FF;
            line-height: 14px;
            padding-left: 6px;
          }
          img {
            cursor: pointer;
          }
        }
        
      }
      
      .table-header {
        background: #FAFAFA;
        border-spacing: 0px;
        width: 100%;
        padding-right: 14px;
        box-sizing: border-box;
        th {
          font-weight: normal;
          font-size: 12px;
          line-height: 40px;
          text-align: center;
          padding-left: 4px;
          border-bottom: 1px solid #E8E8E8;
        }
      }
      .list {
        height: calc(100% - 42px);
        padding-bottom: 60px;
        box-sizing: border-box;
        overflow-y: auto;
        table {
          background: #E8E8E8;
          border-spacing: 0;
          width: 100%;
          .addCart {
            cursor: pointer;
            &:hover {
              span {
                background: #1890FF;
                color: #fff;
              }
            }
            span {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 1px solid #1890FF;
              display: inline-block;
              line-height: 15px;
              text-align: center;
              color: #1890FF;
              font-weight: bold;
            }
          }
          tr:nth-of-type(even){
            td {
              background: #F9F9F9;
            }
          }
          tr:hover {
            td {
              background: #ebf5ff;
            }
          }
          td {
            font-size: 14px;
            text-align: center;
            padding: 20px 0;
            background: #fff;
            border: solid #E8E8E8;
            border-width: 0 1px 1px 0;
            word-break: break-all;
            .operation {
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                // margin-left: 4px;
                margin: 0 2px;
                cursor: pointer;
                color: #1890ff;
              }
              img {
                display: block;
                cursor: pointer;
                margin: 0 2px;
                height: 15px;
              }
            }
            .polling {
              width: 96%;
              height: 4px;
              background: #d8d8d8;
              border-radius: 3px;
              
              margin: 0 auto;
              span {
                height: 100%;
                background: linear-gradient(90deg,#1890ff, #35c3ff);
                border-radius: 3px;
                display: block;
                transition: all .3s cubic-bezier(.08,.82,.17,1) 0s;
              }
            }
            .link {
              color: #1890ff;
              cursor: pointer;
            }
            .partsprice {
              display: flex;
              width: 80px;
              height: 30px;
              color: #fff;
              border-radius: 5px;
              border: none;
              background: #2a98fe;
              margin: 0 auto;
              /*实现垂直居中*/
              align-items: center;
              /*实现水平居中*/
              justify-content: center;
              cursor: pointer;
            }
            .trendIcon {
              i {
                font-size: 12px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
          tr.svg-part-active {
            td:nth-child(1) {
              border-left: 1px solid #2a98fe;
            }
            td:nth-last-child(1) {
              border-right: 1px solid #2a98fe;
            }
            
          }
          tr.svg-part-active-top {
            td {
              border-top: 1px solid #2a98fe;
            }
          }
          tr.svg-part-active-bottom {
            td {
              border-bottom: 1px solid #2a98fe;
            }
          }
          tr.filter {
            td {
              color: #f5222d;
            }
          }
          tr.grey {
            opacity: .6;
          }
          tr.partgrey {
            opacity: .4;
          }
        }
      }
    }
    .box-content-h {
      height: calc(100vh - 138px);
    }
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    background: #E5E9EF;
    text-align: center;
    line-height: 30px;
    color: #666;
    font-size: 12px;
    padding: 0 10px;
    opacity: .6;
  }
}



.config {
  padding: 20px;
  .search {
    margin-bottom: 10px;
    input {
      width: 100%;
      height: 40px;
      border: 1px solid #e8e8e8;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .content {
    height: 400px;
    overflow-y: auto;
  }
  .item {
    display: flex;
    line-height: 20px;
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    label {
      display: inline-block;
      width: 50%;
      word-break: break-all;
    }
    span {
      word-break: break-all;
      display: inline-block;
      width: 50%;
    }
  }
}

.part-search {
  margin: 0 20px;
  h5,
  h6 {
    font-size: 14px;
    font-weight: normal;
    padding: 10px 0px;
  }
  h5 {
    color: #666;
    em {
      font-style: normal;
      color: #000;
    }
  }
  .nodata {
    display: flex;
    padding: 10px 0;
    align-items: center;
    h4 {
      font-size: 14px;
      font-weight: normal;
    }
    button {
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      background: #1890ff !important;
      border-radius: 4px;
      color: #fff;
      border: none;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .content {
    height: calc(100vh - 164px);
    overflow-y: auto;
  }
  .bg {
    background: #fbfdff;
    border: 1px solid #e9f5ff;
    margin: 10px auto 0;
    h5 {
      background: #e9f5ff;
      padding: 4px 10px;
      font-weight: normal;
      color: #333;
      strong {
        color: #000;
      }
    }
  }
  .item {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    padding: 20px 0;
    cursor: pointer;
    &:hover {
      background: #f4f9ff;
    }
    .l {
      width: 150px;
      overflow: hidden;
      margin: 0 10px;
      img {
        width: 100%;
      }
    }
    .r {
      flex: 1;
      em {
        font-style: normal;
        color: #2a98fe;
      }
      h6 {
        font-size: 14px;
        margin-bottom: 6px;
      }
      p {
        line-height: 20px;
        font-size: 12px;
        label {
          display: inline-block;
          width: 40px;
        }
      }
    }
  }
  .nav {
    display: flex;
    border-bottom: 1px solid #1890FF;
    li {
      cursor: pointer;
      height: 26px;
      display: block;
      color: #2a98fe;
      padding: 6px 12px 0;
      border-radius: 4px 4px 0 0;
      i {
        margin-right: 4px;
        color: #2a98fe;
      }
      &.active {
        color: #fff;
        background: #2a98fe;
        i {
          color: #fff;
        }
      }
    }
  }
}

.select-config {
  padding: 0 20px 20px;
  .select-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    img {
      height: 50px;
    }
  }
  table {
    background: #E8E8E8;
    border-spacing: 1px;
    width: 100%;
    table-layout: fixed;
    th {
      background: rgb(235, 234, 234);
      padding: 4px;
      text-align: left;
    }
    td {
      background: #fff;
      padding: 4px;
      cursor: pointer;
    }
  }
  .select-scroll {
    max-height: 400px;
    overflow-y: auto;
  }
}

.partDetail {
  .tab {
    display: flex;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    li {
      margin: 0 20px;
      line-height: 40px;
      border-bottom: 2px solid #fff;
      cursor: pointer;
      &.active {
        border-bottom-color: #1890FF;
        color: #1890ff;
      }
    }
  }
  .content {
    background: #F3F3F3;
    padding: 10px;
    height: 500px;
    overflow-y: auto;
    .row {
      display: flex;
      .basic {
        flex: 1;
        // height: 260px;
        min-height: 200px;
        overflow-y: auto;
        background: #fff;
        .item {
          line-height: 20px;
          padding: 10px;
          label {
            display: inline-block;
            width: 90px;
            vertical-align: top;
          }
          span {
            display: inline-block;
            max-width: 252px;
            border-left: 1px solid #e0e0e0;
            margin-left: 8px;
            padding-left: 8px;
          }
        }
      }
      .photo {
        width: 380px;
        height: 260px;
        padding: 10px;
        background: #fff;
        box-sizing: border-box;
        display: flex;
        justify-content: end;
        .active {
          width: 240px;
          height: 240px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #E0E0E0;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
          }
          .activeL,
          .activeR {
            position: absolute;
            font-size: 20px;
            line-height: 30px;
            display: none;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: rgba(37, 37, 37, 0.5);
            top: 50%;
            margin-top: -15px;
            text-align: center;
            color: #fff;
            cursor: pointer;
          }
          .activeL {
            left: 5px;
          }
          .activeR {
            right: 5px;
          }
          &:hover {
            .activeL,
            .activeR {
              display: block;
            }
          }
        }
        .list {
          margin-left: 8px;
          width: 140px;
          height: 100%;
          overflow-y: auto;
          .upload {
            width: 50px;
            height: 50px;
            font-size: 20px;
            text-align: center;
            line-height: 50px;
            border: 1px solid #E0E0E0;
            cursor: pointer;
            margin-right: 4px;
          }
          .qrcode {
            width: 50px;
            height: 50px;
            font-size: 20px;
            border: 1px solid #E0E0E0;
            position: relative;
            margin-right: 4px;
            &:hover {
              div {
                display: block;
              }
            }
            div {
              width: 120px;
              height: 120px;
              position: absolute;
              top: -1px;
              right: -1px;
              border: 1px solid #E0E0E0;
              display: none;
              z-index: 1;
            }
            img {
              width: 100%;
            }
          }
          p {
            font-size: 12px;
            text-align: center;
          }
          .item {
            width: 50px;
            height: 50px;
            border: 1px solid #E0E0E0;
            cursor: pointer;
            margin-top: 4px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
            position: relative;
            display: inline-block;
            img {
              max-width: 100%;
            }
            &.active {
              border-color: #1890FF;
            }
            span {
              position: absolute;
            }
            .authority {
              display: block;
              top: -6px;
              left: -26px;
              height: 16px;
              padding: 6px 20px 2px;
              background-color: #1890ff;
              color: #fff;
              font-size: 12px;
              transform: rotate(-45deg);
            }
            .removePic {
              bottom: 2px;
              right: 2px;
              font-size: 12px;
              color: #666;
              display: none;
              &:hover {
                color: #1890ff;
              }
            }
            &:hover {
              .removePic {
                display: block;
              }
            }
          }
        }
      }
    }
    .box {
      background: #fff;
      margin-top: 10px;
      padding-top: 10px;
      h5 {
        border-left: 3px solid #1890FF;
        font-size: 14px;
        padding-left: 8px;
        margin-bottom: 10px;
      }
      .table-header {
        background: #FAFAFA;
        border-spacing: 0px;
        width: 100%;
        padding-right: 14px;
        box-sizing: border-box;
        th {
          font-weight: normal;
          font-size: 14px;
          line-height: 40px;
          text-align: center;
          padding-left: 4px;
          border-bottom: 1px solid #E8E8E8;
        }
      }
      table {
        background: #E8E8E8;
        border-spacing: 0;
        width: 100%;
        caption {
          background: #E8E8E8;
          line-height: 30px;
          text-align: left;
          padding: 0 10px;
          font-size: 14px;
        }
        td {
          font-size: 14px;
          text-align: center;
          padding: 16px 0;
          background: #fff;
          border: solid #E8E8E8;
          border-width: 0 0 1px 0;
          word-break: break-all;
          p {
            line-height: 20px;
            vertical-align: middle;
            padding-left: 14px;
            position: relative;
            .us {
              display: inline-block;
              border: solid #d8d8d8;
              border-width: 1px 0 0 1px;
              width: 10px;
              height: 19px;
              position: absolute;
              top: 10px;
              left: 0;
            }
  
            &:last-child {
              .us {
                border-width: 1px 0 0 0;
              }
            }
          }
          .flex {
            display: flex;
            align-items: center;
            span {
              margin: 0 6px;
            }
          }
          .link {
            color: #1890ff;
            cursor: pointer;
          }
          .but {
            border: 1px solid #1890FF;
            background: #fff;
            color: #1890FF;
            padding: 2px 6px;
            cursor: pointer;
            border-radius: 4px;
          }
        }
        tr.svg-part-active {
          td:nth-child(1) {
            border-left: 1px solid #2a98fe;
          }
          td:nth-last-child(1) {
            border-right: 1px solid #2a98fe;
          }
          
        }
        tr.svg-part-active-top {
          td {
            border-top: 1px solid #2a98fe;
          }
        }
        tr.svg-part-active-bottom {
          td {
            border-bottom: 1px solid #2a98fe;
          }
        }
        tr.filter {
          td {
            color: #f5222d;
          }
          tr.grey {
            opacity: .6;
          }
        }
      }
      .tabBrand {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 0 10px;
        h6 {
          font-size: 14px;
        }
        ul {
          display: flex;
          align-items: center;
          li {
            margin-left: 10px;
            cursor: pointer;
            &.active {
              color: #1890ff;
            }
          }
        }
      }
      .supplierTable {
        td {
          font-size: 14px;
          padding: 6px;
          border-bottom: 1px solid #f2f2f2;
          text-align: left;
          .link {
            color: #1890ff;
            cursor: pointer;
          }
          img {
            max-width: 90%;
            max-height: 40px;
          }
          button {
            width: 40px;
            height: 24px;
            line-height: 24px;
            background: #1890ff;
            border-radius: 4px;
            color: #fff;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
    .stocks {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 188px;
        margin-left: 10px;
        background: #FAFDF8;
        border: 1px solid #F0F0F0;
        border-radius: 4px;
        margin-bottom: 8px;
        box-sizing: border-box;
        padding: 4px 6px;
        position: relative;
        .flex {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          h6 {
            border-radius: 4px;
            background: rgba(35,149,254,.1);
            padding: 0 4px;
            font-size: 12px;
            color: #2395fe;
            white-space: nowrap;
            margin: 0;
            line-height: 24px;
          }
          em {
            font-weight: bold;
            font-size: 16px;
            color: #fa3e3d;
            line-height: 24px;
            font-style: normal;
          }
          p {
            color: #888;
            line-height: 24px;
            display: block;
            margin-right: 10px;
            font-size: 12px;
            color: #888;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .info {
          position: absolute;
          bottom: 68px;
          background: #fff;
          box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
          padding: 6px;
          display: none;
        }
        &:hover {
          .info {
            display: block;
          }
        }
      }
    }
  }
}

.trend {
  padding: 10px;
  h5 {
    font-size: 18px;
    text-align: left;
    color: #000000;
    line-height: 20px;
  }
  .chart {
    height: 600px;
  }
  .no {
    display: flex;
    height: 600px;
    align-items: center;
    justify-content: center;
  }
}

.pollingVin {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: calc(100vh - 100px);
  z-index: 99;
  background: rgba($color: #ffffff, $alpha: .2);
  top: 100px;

  div {
    width: 20%;
    height: 20px;
    background: #d8d8d8;
    border-radius: 8px;
    margin: 0 auto;

  }
  span {
    height: 100%;
    background: linear-gradient(90deg,#1890ff, #35c3ff);
    border-radius: 8px;
    display: block;
    transition: all 10s cubic-bezier(.08,.82,.17,1) 0s;
  }
}

.searchPartInput {
  position: relative;
  display: inline-block;
  z-index: 99;
  input {
    width: 190px;
    height: 28px;
    line-height: 28px;
    padding: 0 4px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
  }
  i {
    position: absolute;
    right: 10px;
    border-left: 1px solid #D9D9D9;
    padding-left: 6px;
    top: 7px;
    color: #999999;
    cursor: pointer;
  }
  .hot {
    position: absolute;
    width: 198px;
    height: 280px;
    top: 29px;
    overflow-y: auto;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    background: #fff;
    li {
      font-size: 12px;
      line-height: 28px;
      cursor: pointer;
      padding: 0 4px;
      &:hover {
        background: #f1fafc;
      }
    }
  }
}

.part {
  padding: 10px;
  height: 100%;
  h5 {
    background: #eaf5ff;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 10px;
    font-weight: normal;
    color: #454545;
    button {
      height: 24px;
      line-height: 24px;
      padding: 0 4px;
      border: 1px solid #D9D9D9;
      background: #fff;
      cursor: pointer;
      border-radius: 4px;
      font-size: 12px;
      i {
        font-size: 14px;
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    line-height: 20px;
    padding: 10px 0;
    h6 {
      font-size: 14px;
      width: 50px;
    }
    ul {
      font-size: 14px;
      li {
        margin-right: 10px;
        padding: 2px 4px;
        cursor: pointer;
        display: inline-block;
        &.active {
          padding: 1px 3px;
          color: #1890ff;
          background: #eaf5ff;
          border: 1px solid #1890ff;
          border-radius: 3px;
        }
      }
    }
  }
  .tableHeader {
    background: #FAFAFA;
    border-spacing: 0px;
    width: 100%;
    padding-right: 14px;
    box-sizing: border-box;
    th {
      font-weight: normal;
      font-size: 12px;
      line-height: 40px;
      text-align: center;
      padding-left: 4px;
      border-bottom: 1px solid #E8E8E8;
    }
  }
  .tableBody {
    height: calc(100vh - 160px);
    padding-bottom: 60px;
    box-sizing: border-box;
    overflow-y: auto;
    table {
      background: #E8E8E8;
      border-spacing: 0;
      width: 100%;
      tr {
        cursor: pointer;
      }
      tr:nth-of-type(even){
        td {
          background: #F9F9F9;
        }
      }
      tr:hover {
        td {
          background: #ebf5ff;
        }
      }
      td {
        font-size: 14px;
        text-align: center;
        padding: 20px 0;
        background: #fff;
        border: solid #E8E8E8;
        border-width: 0 1px 1px 0;
        word-break: break-all;
        .operation {
          align-items: center;
          justify-content: center;
          span {
            // margin-left: 4px;
            margin: 0 6px;
            cursor: pointer;
            color: #1890ff;
          }
          img {
            display: block;
            cursor: pointer;
            margin: 0 2px;
            height: 15px;
          }
        }
        .polling {
          width: 96%;
          height: 4px;
          background: #d8d8d8;
          border-radius: 3px;
          
          margin: 0 auto;
          span {
            height: 100%;
            background: linear-gradient(90deg,#1890ff, #35c3ff);
            border-radius: 3px;
            display: block;
            transition: all .3s cubic-bezier(.08,.82,.17,1) 0s;
          }
        }
        .link {
          color: #1890ff;
          cursor: pointer;
        }
        .partsprice {
          display: flex;
          width: 80px;
          height: 30px;
          color: #fff;
          border-radius: 5px;
          border: none;
          background: #2a98fe;
          margin: 0 auto;
          /*实现垂直居中*/
          align-items: center;
          /*实现水平居中*/
          justify-content: center;
          cursor: pointer;
        }
        .trendIcon {
          i {
            font-size: 12px;
            color: #1890ff;
            cursor: pointer;
          }
        }
      }
      tr.svg-part-active {
        td:nth-child(1) {
          border-left: 1px solid #2a98fe;
        }
        td:nth-last-child(1) {
          border-right: 1px solid #2a98fe;
        }
        
      }
      tr.svg-part-active-top {
        td {
          border-top: 1px solid #2a98fe;
        }
      }
      tr.svg-part-active-bottom {
        td {
          border-bottom: 1px solid #2a98fe;
        }
      }
      tr.filter {
        td {
          color: #f5222d;
        }
      }
      tr.grey {
        opacity: .6;
      }
    }
  }
}

.nodata {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vinSearch {
  width: 654px;
  margin: 0 auto 0;
  text-align: center;
  padding: 60px 100px;
  .brand {
    display: flex;
    margin-top: 40px;
    li {
      cursor: pointer;
      padding: 2px 8px;
      border-radius: 4px 4px 0 0;
      font-size: 14px;
      &.active {
        color: #fff;
        background: #1890FF;
      }
    }
  }
  .input {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 0px;
    em {
      position: absolute;
      top: -20px;
      right: 5px;
      font-size: 12px;
      font-style: normal;
      cursor: pointer;
      &:hover {
        color: #1890FF;
      }
    }
    .count {
      position: absolute;
      top: 0;
      right: 66px;
      line-height: 38px;
      color: #a8a8a8;
      font-size: 12px;
    }
    input {
      width: 575px;
      height: 38px;
      line-height: 38px;
      padding: 0 10px;
      border: 1px solid #1890FF;
      font-size: 16px;
      outline: none;
    }
    button {
      width: 56px;
      height: 40px;
      line-height: 40px;
      border-radius: 0 4px 4px 0;
      background: #1890FF;
      border: none;
      padding: 0 14px;
      color: #fff;
      cursor: pointer;
    }
  }
  .catalog {
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    height: 34px;
    margin-top: 6px;
    border-radius: 4px;
    overflow: hidden;
    ul {
      display: flex;
      li {
        cursor: pointer;
        &:hover {
          background: #e0e0e0;
        }
        img {
          height: 34px;
          display: block;
        }
      }
    }
    h5 {
      background: #e0e0e0;
      font-size: 14px;
      line-height: 34px;
      padding: 0 8px;
      font-weight: normal;
      cursor: pointer;
      &:hover {
        background: #e0e0e0;
      }
    }
  }
  .history {
    text-align: left;
    margin-top: 20px;
    ul {
      display: flex;
      li {
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        padding: 2px 8px;
        &.active {
          color: #1890FF;
          background: #e7f1fe;
        }
      }
    }
    .item {
      background: #f0f0f0;
      margin-top: 10px;
      font-size: 12px;
      padding: 4px;
      cursor: pointer;
      display: flex;
      border-radius: 4px;
      span {
        margin-right: 10px;
      }
      &:hover {
        background: #e0e6eb;
      }
    }
  }
  
}
.catalogModal {
  height: 600px;
  padding: 10px;
  .box {
    background: #fff;
    
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid #F3F3F3;
      border-width: 1px 0;
      .acronym {
        display: flex;
        align-items: center;
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
        img {
          height: 38px;
        }
        h6 {
          font-size: 14px;
          font-weight: normal;
        }
        ul {
          display: flex;
          li {
            margin-right: 10px;
            cursor: pointer;
            font-size: 14px;
            &.active {
              color: #2b99ff;
            }
            &:hover {
              color: #2b99ff;
            }
          }
        }
      }
      .type {
        label {
          margin-left: 12px;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    
    .brands {
      height: 550px;
      overflow-y: auto;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        border: 1px solid #F3F3F3;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 10px 10px 0 0;
        border-radius: 3px;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
        }
        img {
          height: 60%;
        }
        p {
          text-align: center;
          word-break: break-all;
          padding: 0;
        }
      }
    }
    .model {
      height: 550px;
      display: flex;
      .item {
        height: 100%;
        flex: 1 1;
        margin-right: 2px;
        border: 1px solid #F3F3F3;
        
        h5 {
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          padding: 0 16px;
          background: #f4f3f3;
          font-weight: normal;
        }
        input {
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          width: 94%;
          margin: 6px auto;
          padding: 0 6px;
          display: block;
          border: 1px solid #F3F3F3;
        }
        ul {
          width: 100%;
          height: calc(100% - 80px);
          overflow-y: auto;
          li {
            padding: 10px 16px;
            cursor: pointer;
            font-size: 14px;
            &:hover {
              color: #2b99ff;
            }
            &:nth-child(even) {
              background: #F9FBFF;
            }
            &.active {
              background: #2b99ff;
              color: #fff;
            }
            &.grey {
              color: #b6b5b5;
            }
            span {
              display: inline-block;
              position: relative;
              .guide {
                position: absolute;
                top: 0;
                right: -20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: rgba(43,153,255, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                  width: 14px;
                  height: 14px;
                  border-radius: 50%;
                  background: #2B99FF;
                  display: block;
                }
                div {
                  width: 100px;
                  height: 36px;
                  line-height: 36px;
                  background: #1A90FF;
                  border-radius: 4px;
                  position: absolute;
                  top: 38px;
                  left: 0;
                  font-size: 14px;
                  color: #ffffff;
                  text-align: center;
                  em {
                    position: absolute;
                    top: -18px;
                    left: 7px;
                    color: #1A90FF;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.parts {
  position: relative;
  padding: 0;
  .top {
    padding: 10px;
  }
  .back {
    position: absolute;
    z-index: 999;
    left: 0px;
    top: 0px;
    font-size: 12px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    border-right: 1px solid #cbcbcb;
    color: #666;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      color: #1890FF;
    }
  }
}