// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
$radioWarpPrefixCls: "radio";
$radioInnerPrefixCls: "#{$radioWarpPrefixCls}-inner";

$color-unchecked: #ffffff;
$color-unchecked-border: #d9d9d9;
$color-checked: #1890ff;
$color-disabled: #f3f3f3;
$color-checked-disabled-border: #cccccc;
.radio-label {
  user-select: none;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
  vertical-align: middle;
  font-weight: 400;
}
.#{$radioWarpPrefixCls} {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  user-select: none;
  cursor: pointer;

  &:after {
    border-color: $color-disabled;
    border-collapse: separate;
    animation-name: none;
  }

  &:hover .#{$radioInnerPrefixCls},
  .#{$radioWarpPrefixCls}-input:focus + .#{$radioInnerPrefixCls} {
    border-color: $color-checked;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  }

  .#{$radioWarpPrefixCls}-input {
    position: absolute;
    left: 0;
    z-index: 9999;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .#{$radioInnerPrefixCls} {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 14px;
    height: 14px;
    background-color: $color-unchecked;
    border-width: 1px;
    border-style: solid;
    border-color: $color-unchecked-border;
    border-radius: 100px;
    transition: all 0.3s;
    box-sizing: border-box;

    &:after {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 14px;
      height: 14px;
      background-color: $color-checked;
      border-top: 0;
      border-left: 0;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      content: " ";
    }
  }
}
/* Checked state */
.#{$radioWarpPrefixCls}-checked {
  &:after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid $color-checked;
    border-radius: 50%;
    animation: CheckboxEffect 0.36s ease-in-out;
    animation-fill-mode: both;
    content: "";
  }

  .#{$radioInnerPrefixCls} {
    border-color: $color-checked;

    &:after {
      transform: scale(0.6);
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      transform-origin: center center;
    }
  }
}
/* Disabled state */
.#{$radioWarpPrefixCls}-disabled,
.#{$radioWarpPrefixCls}-readonly {
  &.#{$radioWarpPrefixCls}-checked {
    &:hover {
      .#{$radioInnerPrefixCls} {
          border-color: $color-unchecked-border;
      }
    }

    .#{$radioInnerPrefixCls} {
      border-color: $color-checked-disabled-border;

      &:after {
        animation-name: none;
        background-color: $color-unchecked-border;
      }
    }
  }

  &:hover .#{$radioInnerPrefixCls} {
    border-color: $color-disabled;
  }

  .#{$radioInnerPrefixCls} {
    border-color: $color-checked-disabled-border;

    &:after {
      background-color: $color-unchecked-border;
      animation-name: none;
    }
  }

  .#{$radioWarpPrefixCls}-input {
    cursor: default;
  }
}
@keyframes CheckboxEffect {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.5;
    visibility: visible;
  }

  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
    visibility: hidden;
  }
}
