// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.loading {
  position: relative;
  .opacity {
    width: 100%;
    opacity: 0.4;
    background: #fff;
    content: '';
    pointer-events: none;
  }
  .transition {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 80px;
      height: 80px;
      border: 4px solid #1890ff;
      border-bottom-color: transparent;
      border-radius: 50%;
      animation: load 1s infinite linear;
      display: block;
    }
    
    @keyframes load {
      from {
        transform:rotate(0deg);
        -ms-transform:rotate(0deg);
      }
      to {
        transform:rotate(360deg);
        -ms-transform:rotate(360deg);
      }
    }
  }
  &.min {
    width: 20px;
    height: 20px;
    display: inline-block;
    .transition {
      display: inline-block;
      span {
        width: 16px;
        height: 16px;
        border-width: 2px;
      }
    }
    
  }
  
}


@keyframes rotate{
  100%{
    transform: rotate(720deg) translate3d(0, 0, 0);
  }
}

.general,
.white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(236, 230, 230,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  span {
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(24, 144, 255, 1);
    border-radius: 50%;
    transform-origin: 5px 15px;
    animation: rotate 2s linear infinite;
    &::before{
      position: absolute;
      width: 10px;
      height: 10px;
      background: rgba(24, 144, 255, 0.35);
      border-radius: 50%;
      left: -8.66px;
      top: 15px;
      content: "";
      display: inline-block;
    }
    &::after{
      position: absolute;
      width: 10px;
      height: 10px;
      background: rgba(24, 144, 255, 0.65);
      border-radius: 50%;
      left: 8.66px;
      top: 15px;
      content: "";
      display: inline-block;
    }
  }
}

 
.white {
  span {
    background: rgba(255, 255, 255, .4);
  }
  span::before {
    background: rgba(255, 255, 255, .8);
  }
  span::after {
    background: rgba(255, 255, 255, .6);
  }
}

