// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.threeModel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9998;
  .logo {
    height: 40px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 0;
    z-index: 100;
    span {
      display: block;
      width:  2px;
      background: #e4e4e4;
      margin-left: 16px;
      height: 20px;
      color: #000000;
    }
    img {
      height: 20px;
    }
  }
  .closed {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
  }
  .menu {
    position: absolute;
    z-index: 99;
    text-align: center;
    width: 100%;
    line-height: 40px;
    background: #fff;
    box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.1);
    li {
      display: inline-block;
      padding: 0 20px;
      cursor: pointer;
      &.active {
        color: #1890ff;
        font-weight: bold;
      }
    }
  }
  .content {
    display: flex;
    height: 100%;

    .l {
      width: 61.8%;
      height: calc(100% - 40px);
      margin-top: 40px;
      position: relative;
    }
    .r {
      width: 38.2%;
      padding: 60px 20px 0;
      box-sizing: border-box;
      border-left: 1px solid #e4e4e4;
      .sample {
        max-width: 100%;
        margin-top: 20px;
      }
      .part {
        height: calc(100% - 33px);
        overflow-y: auto;
        .item {
          display: flex;
          border-bottom: 1px solid $border-color-base;
          padding: 20px 0;
          &:hover {
            background: #ebf5ff;
          }
          .itemL {
            width: 150px;
            height: 150px;
            overflow: hidden;
            margin: 0 10px;
            img {
              width: 100%;
            }
          }
          .itemR {
            flex: 1;
            em {
              font-style: normal;
              color: $primary-color;
            }
            h5 {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .itemRP {
              line-height: 26px;
              display: flex;
              align-items: center;
              label {
                width: 100px;
              }
              .operation {
                display: flex;
                align-items: center;
                span {
                  margin-left: 4px;
                  cursor: pointer;
                  color: #1890ff;
                }
              }
            }
            .trendIcon {
              i {
                font-size: 12px;
                color: #1890ff;
                cursor: pointer;
              }
            }
            .link {
              color: #1890ff;
              cursor: pointer;
            }
            .polling {
              flex: 1;
              width: auto;
              height: 4px;
              border-radius: 3px;
              span {
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
  
  
  
}

.trend {
  padding: 10px;
  h5 {
    font-size: 18px;
    text-align: left;
    color: #000000;
    line-height: 20px;
  }
  .chart {
    height: 600px;
  }
  .no {
    display: flex;
    height: 600px;
    align-items: center;
    justify-content: center;
  }
}




.three {
  width: 100%;
  height: 100%;
  .model {
    width: 100%;
    height: 100%;
  }
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .polling {
    width: 80%;
    height: 16px;
    line-height: 16px;
    background: #d8d8d8;
    border-radius: 12px;
    margin: 0 auto;
    span {
      height: 100%;
      background: linear-gradient(90deg, #1890ff, #35c3ff);
      border-radius: 16px;
      display: block;
      transition: all .2s cubic-bezier(.08,.82,.17,1) 0s;
      text-align: right;
      color: #fff;
    }
  }
  .sliderBox {
    position: absolute;
    width: 100%;
    bottom: 60px;
    left: 0px;
    .slider {
      width: 260px;
      margin: 0 auto;
    }
  }
  .treeTools {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    border: 1px solid #E4E4E4;
    z-index: 9999;
  }
  .tree {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
    max-height: 100%;
    background: rgba(255, 255, 255, 0.8);
    overflow-y: auto;
    border: 1px solid #E4E4E4;
    padding: 10px;
    border-radius: 2px;
    .tools {
      position: absolute;
      right: 6px;
      top: 6px;
      cursor: pointer;
      border: 1px solid #E4E4E4;
    }
    .item {
      margin-bottom: 6px;
      h6 {
        font-size: 14px;
        font-weight: normal;
        padding: 4px 0;
        display: flex;
        align-items: center;
        i {
          font-size: 12px;
          margin-right: 6px;
          cursor: pointer;
        }
      }
      ul {
        font-size: 14px;
        li {
          padding: 4px 0 4px 30px;
        }
      }
      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        input {
          margin-right: 6px;
        }
      }
    }
  }
}
