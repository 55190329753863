// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.home {
  width: 100%;
  .banner {
    text-align: center;
    position: relative;
    width: 100%;
    height: 346px;
    margin: 0 auto;
    i {
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 30px;
      color: #c8c9cd;
      cursor: pointer;
    }
    img {
      width: 100%;
    }
    div {
      background: #f7fbfe url(../../assets/images/banner.png) no-repeat center;
      cursor: pointer;
      height: 100%;
      width: 100%;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.07);
    }
  }
  .logo {
    text-align: center;
    padding: 80px 0 0;
    img {
      width: 330px;
    }
  }
  .search {
    display: flex;
    align-items: center;
    height: 60px;
    width: 650px;
    margin: 10px auto 0;
    .box {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      .input {
        flex: 1;
        border: 1px solid $primary-color;
        border-radius: 6px 0 0 6px;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        background: #fff;
        display: flex;
        align-items: center;
        .selected {
          height: 24px;
          background: #e0f0ff;
          padding: 0 10px;
          margin-right: 10px;
          line-height: 24px;
          cursor: pointer;
          transition: all 2s;
          border-radius: 4px;
          .img {
            display: flex;
            align-items: center;
            img {
              height: 24px;
            }
          }
          i {
            opacity: .2;
            margin-left: 4px;
          }
        }
        .in {
          flex: 1;
          position: relative;
          .tips {
            z-index: 9999;
            padding: 4px 8px;
            background: #fff;
            border: 1px solid #FF6018;
            position: absolute;
            top: 26px;
            left: -10px;
            color: #FF6018;
            border-radius: 4px;
            font-size: 12px;
            box-shadow: 4px 4px 8px 0 rgba(41,16,5,0.09);
            .down {
              position: absolute;
              top: -12px;
              left: 30px;
            }
          }
          input {
            width: 100%;
            border: none;
            appearance: none;
            font-size: 16px;
            &::-webkit-input-placeholder {
              color: #b8ddff;
            }
          }
          i {
            color: #1890FF;
            font-size: 24;
            cursor: pointer;
            position: absolute;
            right: 10px;
          }
        }
      }
      
      button {
        width: 64px;
        height: 40px;
        border: none;
        background: $primary-color;
        border-radius: 0 6px 6px 0;
        cursor: pointer;
        i {
          font-size: 24px;
          color: #fff;
        }
      }
      .vinHistory {
        width: 100%;
        background: #fff;
        min-height: 100px;
        border-radius: 4px;
        box-shadow: 4px 10px 19px 0px rgba(50, 138, 219, 0.3);
        position: absolute;
        top: 40px;
        left: 0px;
        z-index: 999;
        .item {
          display: flex;
          justify-content: space-between;
          padding: 12px;
          border-bottom: 1px solid #e0f0ff;
          cursor: pointer;
        }
      }
      .search-content {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        box-shadow: 4px 10px 19px 0px rgba(50, 138, 219, 0.3);
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 999;
        .h {
          padding: 0 10px;
          height: 40px;
          width: 100%;
          box-sizing: border-box;
          line-height: 40px;
          border-bottom: 1px solid #d7e6f5;
          position: relative;
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          img {
            height: 38px;
          }
          h6 {
            font-size: 14px;
            font-weight: normal;
          }
          ul {
            display: flex;
            li {
              margin-right: 10px;
              cursor: pointer;
              font-size: 14px;
              
              &:hover {
                color: #2b99ff;
              }
            }
          }
          .cancel {
            position: absolute;
            right: 10px;
            cursor: pointer;
            span {
              font-size: 12px;
            }
            
          }
        }
        .b {
          height: 360px;
        }
        .group {
          display: flex;
          border-bottom: 1px solid #d7e6f5;
          height: 100%;
          h5 {
            width: 20%;
            background: #e0f0ff;
            text-align: center;
            font-size: 16px;
            padding-top: 20px;
          }
          .item {
            width: 80%;
            box-sizing: border-box;
            max-height: 100%;
            overflow-y: auto;
            span {
              display: inline-block;
              line-height: 20px;
              padding: 10px 16px;
              box-sizing: border-box;
              cursor: pointer;
              width: 33%;
              font-size: 14px;
              &:hover {
                color: $primary-color;
              }
              &.special {
                width: 100%;
                &:nth-child(even) {
                  background: #F9FBFF;
                }
              }
            }
          }
        }
      }
      .ocr {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        box-shadow: 4px 10px 19px 0px rgba(50, 138, 219, 0.3);
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 999;
        .del-ocrimg {
          position: absolute;
          top: 2px;
          right: 2px;
          font-size: 20px;
          cursor: pointer;
          opacity: 0.5;
        }
        .upload {
          min-height: 200px;
          text-align: center;
          cursor: pointer;
          span {
            font-size: 50px;
            color: $primary-color;
            margin: 30px auto 10px;
            display: inline-block;
          }
          button {
            height: 40px;
            width: auto;
            line-height: 40px;
            padding: 0 12px;
            border-radius: 4px;
            background: $primary-color;
            color: #fff;
            border: 0;
            font-size: 18px;
            margin: 20px auto 0;
            display: block;
            cursor: pointer;
          }
        }
        .ocrImg {
          max-height: 200px;
          text-align: center;
          background: #e0f0ff;
          padding: 10px 0;
          cursor: pointer;
          img {
            max-height: 200px;
            max-width: 96%;
          }
        }
        .uploaded {
          input {
            border: 1px solid #d2e8fc;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            padding: 0 16px;
            width: 100%;
            box-sizing: border-box;
            letter-spacing: 16px;
          }
          button {
            width: 96%;
            height: 40px;
            line-height: 40px;
            background: $primary-color;
            color: #fff;
            border: 0;
            font-size: 18px;
            margin: 10px auto 0;
            display: block;
            cursor: pointer;
            border-radius: 4px;
          }
          p {
            text-align: center;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .free {
    width: 650px;
    background: rgba(255,255,255,0.20);
    border: 2px solid rgba(255,255,255,0.50);
    margin: 20px auto 0;
    cursor: pointer;
    h6 {
      font-size: 16px;
      text-align: center;
      color: #000000;
      margin-top: 60px;
    }
    p {
      font-size: 36px;
      text-align: center;
      color: #000000;
      line-height: 50px;
      margin-top: 20px;
    }
    button {
      width: 100%;
      height: 69px;
      background: rgba(45,153,255,0.50);
      font-size: 20px;
      text-align: center;
      color: #ffffff;
      border: 0;
      margin-top: 40px;
      cursor: pointer;
    }
  }
  .advantages {
    width: 800px;
    margin: 30px auto 0;
    display: flex;
    div {
      width: 33%;
      box-sizing: border-box;
      padding: 30px;
      text-align: center;
      span {
        font-size: 50px;
      }
      h6 {
        color: $primary-color;
        font-style: normal;
        font-size: 18px;
        margin-top: 10px;
      }
      p {
        line-height: 28px;
        font-size: 18px;
        margin-top: 30px;
        color: #939599;
      }
    }
  }
  .hot {
    width: 650px;
    background: rgba(255,255,255,0.20);
    border: 2px solid rgba(255,255,255,0.50);
    margin: 20px auto 0;
    .hotHead {
      height: 30px;
      background: rgba(255,255,255,0.50);
      display: flex;
      justify-content: space-between;
      ul {
        li {
          cursor: pointer;
          text-align: center;
          line-height: 30px;
          display: inline-block;
          margin: 0 10px;
          &.active {
            color: #1890FF;
            border-bottom: 2px solid #1890FF;
          }
        }
      }
      span {
        line-height: 30px;
        color: #666;
        margin-right: 10px;
      }
    }
    .hotBody {
      height: 108px;
      overflow-y: auto;
      li {
        line-height: 36px;
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;
        color: #333;
        &:hover {
          color: #1890FF;
        }
        
      }
    }
  }
  .footer {
    margin-top: 100px;
    margin: 100px 0 12px;
    line-height: 26px;
  }
  @keyframes bounce {
    0% { font-size: 20px; }
    50% { font-size: 24px; }
    100% { font-size: 20px; }
  }
  .videoIcon {
    position: absolute;
    top: 60px;
    right: 30px;
    width: 40px;
    height: 40px;
    background: #1890FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      color: #fff;
      font-size: 22px;
    }
    &.animation {
      i {
        animation: bounce .5s linear infinite;
      }
    }
  }
}
.freeRegister {
  width: 650px;
  margin: 10px auto 0;
  padding: 6px 8px;
  box-sizing: border-box;
  background: #FF6018;
  color: #fff;
  border-radius: 4px;
}
.recommendedBrand,
.recommendedVin {
  width: 650px;
  margin: 30px auto;
  box-sizing: border-box;
}

.recommendedVin {
  background: #F5FAFF;
  padding: 10px;
  h6 {
    font-size: 16px;
    em {
      font-size: 12px;
      color: #999999;
      font-style: normal;
      margin-left: 10px;
    }
  }
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      margin: 0px 20px 0px 0;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      color: #333333;
      &:hover {
        color: #1890FF;
      }
      img {
        height: 40px;
      }
    }
    li:last-child {
      margin: 0;
    }
  }
}

.recommendedBrand {
  .t {
    display: flex;
    justify-content: space-between;
    h6 {
      font-size: 16px;
    }
    em {
      font-size: 14px;
      color: #1890FF;
      font-style: normal;
      margin-left: 10px;
      cursor: pointer;
      a {
        color: #1890FF;
      }
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      color: #1890FF;
    }
    li {
      margin: 12px 0 0 0;
      width: 110px;
      display: inline-block;
      cursor: pointer;
      border: 1px solid #F6F6F6;
      border-radius: 4px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 66px;
        background: #f6f7f7;
        position: relative;
        img {
          max-width: 100%;
          max-height: 100%;
        }
        .new {
          position: absolute;
          height: 24px;
          top: -8px;
          right: -4px;
        }
      }
      p {
        text-align: center;
        line-height: 30px;
      }
      &:hover {
        border-color: #1890FF;
      }
    }
  }
}

.trial {
  text-align: center;
  height: 154px;
  line-height: 154px;
  background: url('/assets/images/trial.png') no-repeat;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  .trialLoading {
    width: 60px;
    height: 20px;
    display: inline-block;
  }
}

.videoImg {
  width: 650px;
  margin: 0 auto;
  h6 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .c {
    display: flex;
    justify-content: space-between;
    div {
      width: 196px;
      position: relative;
      height: 154px;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        color: #fff;
        line-height: 40px;
        em {
          width: 6px;
          height: 6px;
          display: inline-block;
          background: #fff;
          vertical-align: 2px;
          
        }
      }
      span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        cursor: pointer;
        em {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.40);
          border: 2px solid #fff;
          transition: all .3s;
          i {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            transition: all .3s;
          }
        }
        
        &:hover {
          em {
            width: 40px;
            height: 40px;
            i {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  
}

.select-config {
  padding: 0 20px 20px;
  .select-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    img {
      height: 50px;
    }
  }
  table {
    background: #E8E8E8;
    border-spacing: 1px;
    width: 100%;
    tbody {
      max-height: 400px;
      overflow-y: auto;
    }
    th {
      background: rgb(235, 234, 234);
      padding: 4px;
      text-align: left;
    }
    td {
      background: #fff;
      padding: 4px;
      cursor: pointer;
    }
  }
}


.dwonloadapp {
  margin-top: 20px;
  width: 650px;
  height: 345px;
  background: url('../../assets/images/Mask_group.png');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  .title {
    width: 260px;
    font-size: 18px;
    color: #000000;
    text-align: center;
  }
  .warp-type {
    margin-top: 25px;
    background-color: #f8f8f8;
    width: 390px;
    height: 225px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .warp-img {
      width: 130px;
      height: 130px;
      img {
        width: 130px;
        height: 130px;
      }
    }
  }
}