// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.LRF-page {
  height: 100vh;
  width: 100%;
}

.form-login {
  width: 440px;
  background: #fff;
  margin: 60px auto 0;
  padding: 72px 50px;
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.1);
  .form-wrap-img {
    height: 56px;
    position: absolute;
    top: -28px;
    left: 0;
    text-align: center;
    width: 100%;
    img {
      height: 100%;
    }
  }
  .tips {
    color: #000;
    padding-bottom: 10px;
    line-height: 22px;
  }
}
.form-boot {
  width: 850px;
  margin: 30px auto 0;
  position: relative;
  .form-box-l,
  .form-box-r {
    padding: 60px 50px;
    border-radius: 20px;
    box-sizing: border-box;
    width: 440px;
    background: #fff;
    position: absolute;
  }
  .form-wrap-img {
    display: flex;
    margin-bottom: 8px;
    img {
      width: 168px;
    }
    span {
      display: block;
      line-height: 28px;
      height: 28px;
      font-size: 20px;
      padding: 0 10px;
      border-radius: 10px 0 10px;
      color: #fff;
      margin-left: 10px;
    }
  }
  .form-box-l {
    left: 0;
    box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.1);
    .form-wrap-img {
      span {
        background: #2791F5;
      }
    }
  }
  .form-box-r {
    right: 0;
    box-shadow: -4px 0px 15px 0px rgba(0, 0, 0, 0.1);
    .form-wrap-img {
      span {
        background: #FF6018;
      }
    }
    button {
      background-color: #FF6018;
      border-color: #FF6018;
    }
    .active {
      background-color: #FF6018 !important;
    }
    .form-item-agree {
      span:nth-child(1) {
        span {
          background-color: #FF6018;
          border-color: #FF6018 !important;
        }
      }
    }
    .to-agree {
      color: #FF6018;
    }
    .tips {
      color: #939393;
      position: absolute;
      bottom: 20px;
      width: 100%;
      left: 0;
      text-align: center;
    }
  }
  .mask {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    border-radius: 20px;
    opacity: .8;
    cursor: pointer;
  }
}
.form-boot-new {
  width: 440px;
  margin: 20px auto 0;
  position: relative;
  .form-box-l,
  .form-box-r {
    padding: 30px 50px;
    border-radius: 20px;
    box-sizing: border-box;
    width: 440px;
    background: #fff;
    position: absolute;
  }
  .form-wrap-img {
    margin-bottom: 8px;
    text-align: center;
    img {
      width: 168px;
    }
    span {
      display: block;
      line-height: 28px;
      height: 28px;
      font-size: 20px;
      padding: 0 10px;
      border-radius: 10px 0 10px;
      color: #fff;
      margin-left: 10px;
    }
  }
  .form-box-l {
    left: 0;
    box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.1);
    .form-wrap-img {
      span {
        background: #2791F5;
      }
    }
  }
  .form-box-r {
    right: 0;
    box-shadow: -4px 0px 15px 0px rgba(0, 0, 0, 0.1);
    .form-wrap-img {
      span {
        background: #FF6018;
      }
    }
    button {
      background-color: #FF6018;
      border-color: #FF6018;
    }
    .active {
      background-color: #FF6018 !important;
    }
    .form-item-agree {
      span:nth-child(1) {
        span {
          background-color: #FF6018;
          border-color: #FF6018 !important;
        }
      }
    }
    .to-agree {
      color: #FF6018;
    }
    .tips {
      color: #939393;
      position: absolute;
      bottom: 20px;
      width: 100%;
      left: 0;
      text-align: center;
    }
  }
  .mask {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    border-radius: 20px;
    opacity: .8;
    cursor: pointer;
  }
}
.form-item {
  margin-bottom: 12px;
  display: flex;
  position: relative;
  .form-empty {
    position: absolute;
    left: -12px;
    color: #f00;
  }
  .code {
    width: 120px;
    background: $primary-color;
    border-radius: 4px;
    text-align: center;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 10px;
    &:disabled {
      opacity: .6;
    }
  }

}
.channel {
  width: 100%;
  .channel-box {
    padding: 10px 0;
    div {
      display: flex;
      margin-bottom: 4px;
      span {
        flex: 1;
        text-align: center;
        line-height: 36px;
        border: 1px solid #d2d2d2;
        font-size: 14px;
        cursor: pointer;
        border-left: none;
        &:first-child {
          border-left: 1px solid #d2d2d2;
        }
        &.active {
          background: #1890ff;
          color: #fff;
        }
      }
    }
  }
}
.form-item-btns {
  margin-bottom: 12px;
}
.form-item-pop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .stay-login {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #1890ff;
    line-height: 20px;
    cursor: pointer;
  }
  .to-forget-pwd {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #454545;
    line-height: 20px;
    cursor: pointer;
  }
}
.auth-login-pop {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #d2d2d2;
  line-height: 12px;
  margin-top: 60px;
  margin-bottom: 12px;
}
.auth-login-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .auth-login-img-wrap {
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.form-item-agree {
  margin-top: 12px;
  .agree-msg {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #454545;
    line-height: 20px;
  }
  .to-agree {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: $link-color;
    line-height: 20px;
  }
}

.agreement {
  height: 500px;
  overflow-y: auto;
  padding: 20px;
  font-size: 14px;
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.modal {
  position: fixed;
  z-index: 10000;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  .form-login {
    padding: 72px 50px;
  }
}

.title {
  text-align: center;
  color: #454545;
  font-size: 18px;
  padding: 10px 0;
  h5 {
    font-size: 38px;
    margin: 20px 0;
    font-weight: normal;
  }
}