body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #454545;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}

input {
  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: #c0c0c0;
  }
}

@keyframes antRotate {
  to {
   transform: rotate(405deg)
  }
}

.trans {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
  display: inline-block !important;
}
.red,
.grey,
.blue,
.newred {
  &.rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      border-left-color: #F45F00 !important;
    }
  }
  &.rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      border-bottom-color: #F45F00 !important;
    }
  }
  &.rc-tooltip-placement-bottomLeft {
    .rc-tooltip-arrow {
      border-bottom-color: #F45F00 !important;
    }
  }
  .rc-tooltip-inner {
    background: #F45F00;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
    padding: 0;
    color: #fff;
    overflow: hidden;
  }
  .tips {
    padding: 8px;
    font-size: 14px;
    text-align: right;
    p {
      text-align: left;
    }
    button {
      font-size: 14px;
      color: #FFFFFF;
      border: none;
      padding: 2px 6px;
      background: rgba(255, 255, 255, 0.3);
      margin: 4px 0 0 auto;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.grey {
  &.rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      border-left-color: rgba(0, 0, 0, 0.7) !important;
    }
  }
  &.rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      border-bottom-color: rgba(0, 0, 0, 0.7) !important;
    }
  }
  .rc-tooltip-inner {
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
  }
}

.blue {
  &.rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      border-left-color: #1A90FF !important;
    }
  }
  &.rc-tooltip-placement-bottom,
  &.rc-tooltip-placement-bottomLeft {
    .rc-tooltip-arrow {
      border-bottom-color: #1A90FF !important;
    }
  }
  .rc-tooltip-inner {
    background: #1A90FF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
  }
  .tips {
    .btn {
      position: absolute;
      right: 2px;
      top: 8px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
.newred {
  .tips {
    .btn {
      position: absolute;
      right: 2px;
      top: 8px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
.white {
  .rc-tooltip-arrow {
    border-bottom-color: #fff !important;
  }
  &.rc-tooltip-placement-bottomRight {
    .rc-tooltip-arrow {
      right: 7px !important;
    }
  }

  .rc-tooltip-inner {
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
    padding: 0;
    color: #000;
    overflow: hidden;
    font-size: 14px;
    p {
      line-height: 30px;
      &.out {
        color: #000;
        cursor: pointer;
        border-top: 1px solid #ededed;
        display: block;
        padding-left: 10px;
      }
      a {
        display: block;
        padding-left: 10px;
        color: #000;
        &:hover {
          background: #f0efef;
        }
      }
    }
    .pad {
      padding: 10px
    }
    .msg-content {
      p {
        padding: 2px 12px;
        button {
          border: 0;
          background: #1890ff;
          border-radius: 4px;
          color: #fff;
          padding: 2px 6px;
          cursor: pointer;
        }
      }
    }
  }
}
@keyframes loadingCircle {
  to {
   transform:rotate(360deg)
  }
 }

.ant-btn:before {
  position:absolute;
  inset:-1px;
  z-index:1;
  display:none;
  background:#fff;
  border-radius:inherit;
  opacity:.35;
  transition:opacity .2s;
  content:"";
  pointer-events:none
}
.anticon {
transition:margin-left .3s cubic-bezier(.645,.045,.355,1)
}
.anticon.anticon-plus>svg,
.anticon.anticon-minus>svg {
shape-rendering:optimizeSpeed
}
.anticon svg {
animation:loadingCircle 1s infinite linear
}

div.rc-tabs {
  border: none;
  font-size: 14px;
  .rc-tabs-nav-measure-ping-left::before, 
  .rc-tabs-nav-wrap-ping-left::before {
    box-shadow: inset 10px 0 8px -8px #000014;
    width: 30px;
    border: none;
    pointer-events: none;
    opacity: .3;
  }
  .rc-tabs-nav-measure-ping-right::after, 
  .rc-tabs-nav-wrap-ping-right::after {
    box-shadow: inset -10px 0 8px -8px #000014;
    width: 30px;
    border: none;
    pointer-events: none;
    opacity: .3;
    z-index: 1;
  }
  .rc-tabs-nav-more {
    width: 30px;
    text-align: center;
    border: none;
    background: transparent;
  }
  .rc-tabs-tab-btn {
    font-size: 14px;
    padding: 0 12px;
  }
  .rc-tabs-tab {
    margin-right: 2px;
    border: 1px solid #f0f0f0;
    border-bottom: none;
    background: #fafafa;
    border-radius: 2px 2px 0 0;
  }
  .rc-tabs-tab-active {
    color: #1890ff;
  }
  .rc-tabs-ink-bar {
    background: #1890ff;
    height: 2px;
  }
  .rc-tabs-nav {
    border-bottom: 1px solid #f0f0f0;
  }
}
div.rc-tabs-dropdown {
  z-index: 9999;
  border-color: #f0f0f0;
  font-size: 14px;
  li {
    cursor: pointer;
    &:hover {
      background: #fafafa;
    }
  }
}

#root div.modalTrial {
  background: transparent;
  i {
    right: 50px;
    color: #fff;
    font-size: 20px;
  }
}

.flex {
  display: flex;
}
.flex-s-c-i {
  display: flex !important;
  align-items: center;
}
.flex-s-c {
  display: flex;
  align-items: center;
}
.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rc-tooltip {
  z-index: 999999 !important;
}

$pxList: [2, 5, 8, 10, 12, 15, 20, 30];
// 外边距
@each $i in $pxList {
	.m#{$i} {
		margin: $i + px;
	}
	.mr#{$i} {
		margin-right: $i + px;
	}
	.ml#{$i} {
		margin-left: $i + px;
	}
	.mt#{$i} {
		margin-top: $i + px;
	}
	.mb#{$i} {
		margin-bottom: $i + px;
	}
	.mlr#{$i} {
		margin-left: $i + px;
		margin-right: $i + px;
	}
	.mtb#{$i} {
		margin-top: $i + px;
		margin-bottom: $i + px;
	}
}

// 内边距
@each $i in $pxList {
	.p#{$i} {
		padding: $i + px;
	}
	.pr#{$i} {
		padding-right: $i + px;
	}
	.pl#{$i} {
		padding-left: $i + px;
	}
	.pt#{$i} {
		padding-top: $i + px;
	}
	.pb#{$i} {
		padding-bottom: $i + px;
	}
	.plr#{$i} {
		padding-left: $i + px;
		padding-right: $i + px;
	}
	.ptb#{$i} {
		padding-top: $i + px;
		padding-bottom: $i + px;
	}
}