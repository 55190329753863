// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.catalog {
  margin: 0 20px;
  .vehicle {
    padding-bottom: 90px;
    border-bottom: 10px solid #f0f0f0;
    h2 {
      font-size: 16px;
      color: #000;
      line-height: 60px;
    }
    .select {
      display: flex;
      justify-content: space-between;
      .item {
        width: 24%;
        button {
          height: 40px;
          line-height: 40px;
          background: #2791F5;
          border-radius: 4px;
          padding: 0 20px;
          color: #fff;
          font-size: 16px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
  
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid #F3F3F3;
    border-width: 1px 0;
    .acronym {
      display: flex;
      align-items: center;
      height: 40px;
      white-space: nowrap;
      overflow: hidden;
      flex: 1;
      img {
        height: 38px;
      }
      h6 {
        font-size: 14px;
        font-weight: normal;
      }
      ul {
        display: flex;
        li {
          margin-right: 10px;
          cursor: pointer;
          font-size: 14px;
          
          &:hover {
            color: #2b99ff;
          }
        }
      }
    }
    .type {
      label {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  
  .brand {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      border: 1px solid #F3F3F3;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 10px 10px 0 0;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
      }
      img {
        height: 60%;
      }
      p {
        text-align: center;
        word-break: break-all;
        padding: 0;
      }
    }
  }
  .model {
    height: calc(100vh - 84px);
    display: flex;
    .item {
      height: 100%;
      flex: 1 1;
      margin-right: 2px;
      border: 1px solid #F3F3F3;
      h5 {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        padding: 0 6px;
        background: #f4f3f3;
        font-weight: normal;
        white-space: nowrap;
      }
      input {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        width: 94%;
        margin: 6px auto;
        padding: 0 6px;
        display: block;
        border: 1px solid #F3F3F3;
      }
      ul {
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: auto;
        li {
          padding: 10px 16px;
          cursor: pointer;
          &:hover {
            color: #2b99ff;
          }
          &:nth-child(even) {
            background: #F9FBFF;
          }
          &.active {
            background: #2b99ff;
            color: #fff;
          }
          &.grey {
            color: #b6b5b5;
          }
          span {
            display: inline-block;
            position: relative;
            .guide {
              position: absolute;
              top: 0;
              right: -20px;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: rgba(43,153,255, 0.5);
              display: flex;
              align-items: center;
              justify-content: center;
              i {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #2B99FF;
                display: block;
              }
              div {
                width: 100px;
                height: 36px;
                line-height: 36px;
                background: #1A90FF;
                border-radius: 4px;
                position: absolute;
                top: 38px;
                left: 0;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                em {
                  position: absolute;
                  top: -18px;
                  left: 7px;
                  color: #1A90FF;
                }
              }
            }
          }
        }
      }
    }
  }
}

