// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.tag {
  background:rgba(212, 209, 209, 0.767);
  position: fixed;
  bottom: 44px;
  right: 0px;
  padding: 4px;
  width: 260px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  z-index: 9999;
  box-sizing: border-box;
  h4 {
    line-height: 30px;
    font-size: 14px;
    cursor: move;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 32%;
      background: #fff;
      border-radius: 4px;
      margin: 0 0 3px 3px;
      line-height: 20px;
      height: 70px;
      text-align: center;
      color: #666;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: #fff;
        background: #1890ff;
      }
      &.active {
        color: #fff;
        background: #429aec;
      }
    }
  }

  textarea {
    border: 1px solid #d8d8d8;
    width: 100%;
    height: 60px;
    line-height: 20px;
    padding: 4px;
    box-sizing: border-box;
  }
}
.tool {
  height: 40px;
  width: 260px;
  padding: 2px 4px;
  background:rgba(233, 233, 233, 0.801);
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  z-index: 9999;
  box-sizing: border-box;
  span {
    font-size: 12px;
    text-align: center;
    color: #666;
    line-height: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 5px;
    img {
      width: 100%;
    }
  }
}
.canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  .canvasBorder {
    border: solid rgba(68, 66, 66, 0.5);
    border-width: 40px 10px;
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
  }
  canvas {
    position: absolute;
    z-index: 1;
  }
  textarea {
    position: absolute;
    z-index: 2;
    width: 410px;
    font-size: 20px;
    background: transparent;
    font-family: '微软雅黑';
    color: red;
    display: none;
  }
}